import {
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Select,
	Text,
	Textarea,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import api from 'api'
import { BackArrow } from 'components/Icons/Icons'
import { doc, getDoc } from 'firebase/firestore'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { db } from '../../../firebase'
import { AddressForm } from './AddressForm'
import './Users.scss'
import { schema } from './schema'

const startsWithPlus = value => {
  return value.startsWith('+');
}

const isEmptyObject = obj => {
	return Object.keys(obj).length === 0;
}

const getCountryCodeById = (countries, id) => {
	return countries.find(i => i.id === id)?.code
}

const transformAddressesData = (array, countries) => {
	return array
		.filter(item => !item.isDefault)
		.reduce((acc, item, i) => {
				acc[i + 1] = {
					fullname: item.fullname,
					phone: item.phone,
					country: getCountryCodeById(countries, item.country_id),
					city: item.city,
					postalCode: item.postal_code,
					state: item.state,
					line1: item.line1,
					line2: item.line2,
					id: item.id,
					type: 'update'
				}
				return acc
	}, {})
}

const ExpertsUsersNew = () => {
	const { t } = useTranslation()
	const { userId } = useParams()
	const { search, pathname } = useLocation()
	const history = useHistory()
	const { id } = useSelector(state => state.user)

	const [countries, setCountries] = useState([])
	const [userEmail, setUserEmail] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const [createIsLoading, setCreateIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [phone, setPhone] = useState('')
	const [additionalPhone, setAdditionalPhone] = useState('')
	const [canSendMarketingMessages, setCanSendMarketingMessages] = useState(false)

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
	})

	const addressForm = useForm({
		reValidateMode: 'onBlur',
	});

	const onSubmit = async data => {
		try {
			setCreateIsLoading(true)
			setErrorMessage('')
			const { email, ...rest } = data

			let newUser = {}

			if (userId) {
				// update user
				const userObj = {
					firstName: rest.firstName,
					lastName: rest.lastName,
					email: email,
					gender: rest.gender,
					language: rest.language,
					notes: rest.notes,
					additional_phone: startsWithPlus(additionalPhone) ? additionalPhone : '+' + additionalPhone,
					canSendMarketingMessages,
					shippingAddress: {
						fullname: rest.fullname,
						phone: startsWithPlus(phone) ? phone : '+' + phone,
						country: rest.country,
						city: rest.city,
						postal_code: rest.postal_code,
						state: rest.state,
						line1: rest.line1,
						line2: rest.line2,
					},
				}
				newUser = userObj
				await api.post('updateUser', { id: userId, ...userObj })
			} else {
				// ceate new user
				const { data } = await api.get(`s/silentreg/` + encodeURI(email))

				const { data: refData } = await api.get(`s/new_refncode`)

				const userObj = {
					id: data?.uid,
					firstName: rest.firstName,
					lastName: rest.lastName,
					email: email,
					gender: rest.gender,
					additional_phone: '+' + additionalPhone,
					canSendMarketingMessages,
					shippingAddress: {
						fullname: rest.fullname,
						phone: '+' + phone,
						country: rest.country,
						city: rest.city,
						postal_code: rest.postal_code,
						state: rest.state,
						line1: rest.line1,
						line2: rest.line2,
					},
					expertId: id,
					status: 'registered',
					lStatus: 'registered',
					version: 2,
					role_id: 1,
					checkPayment: -1,
					language: rest?.language,
					notes: rest?.notes,
					ref: refData?.ref,
					invite_code: refData?.code,
					skipQuestionnaire: rest.skipQuestionnaire,
				}

				newUser = userObj
				await api.post('createUser', userObj)
				// .then((res) => {
				//   axios.get(`${CRM_URL}/s/crmadd/31000024064/${data?.uid}`)
				//     .then((result) => {

				//     }).catch(err => {
				//       console.log('err', err)
				//       setErrorMessage(err.message)
				//     });
				// })
			}

			newUser.name =
				(newUser?.firstName &&
					newUser?.lastName &&
					`${newUser?.firstName} ${newUser?.lastName}`) ||
				newUser?.name ||
				'-'
			const storageUsers = JSON.parse(localStorage.getItem(`users_${id}`)) || []
			const newStorageUsers = JSON.stringify(
				userId
					? storageUsers.map(i => {
							if (i.id === userId) {
								return { ...i, ...newUser }
							}
							return i
					  })
					: [
							{
								...newUser,
								created: { seconds: Date.now() / 1000 },
								actionStatus: 'give_assessment',
							},
							...storageUsers,
					  ]
			)

			localStorage.setItem(`users_${id}`, newStorageUsers)

			if (userId) {
				if (history.length > 1) {
					history.goBack();
				} else {
					window.close();
				}
			} else {
				if (search.includes('order')) {
					history.push(`/admin/orders/new/${newUser.id}`)
				} else {
					history.push('/admin/customers')
				}
			}
		} catch (ex) {
			console.log('ceate new user exception', ex)
			setErrorMessage(ex.response.data.message)
		} finally {
			setCreateIsLoading(false)
		}
	}

	const fetchUser = async () => {
		try {
			const { data } = await api.get('userByID', { params: { userId } })

			if (data) {
				let gender = ''

				if (data?.gender === 'male') {
					gender = 'M'
				} else if (data?.gender === 'female') {
					gender = 'F'
				} else if (data?.gender === 'other') {
					gender = 'O'
				}

				setValue('firstName', data?.firstName)
				setValue('lastName', data?.lastName)
				setValue('email', data?.email)
				setUserEmail(data?.email)
				setValue('gender', gender || data?.gender)
				setValue('language', data?.language || 'EN')
				setValue('notes', data?.notes || '')
				setValue('fullname', data?.shippingAddress?.fullname)
				setValue('country', data?.shippingAddress?.country)
				setValue('city', data?.shippingAddress?.city)
				setValue('postal_code', data?.shippingAddress?.postal_code)
				setValue('state', data?.shippingAddress?.state)
				setValue('line1', data?.shippingAddress?.line1)
				setValue('line2', data?.shippingAddress?.line2)
				setPhone(data?.phone || data?.shippingAddress?.phone)
				setAdditionalPhone(data?.additional_phone || '')
				setCanSendMarketingMessages(Boolean(data.canSendMarketingMessages))
			}
		} catch (err) {
			console.log('err', err)
		}
	}

	const sendAdditionalAddress = useCallback(async (key) => {
		try {
			const data = addressForm.getValues(key)
			if (data?.type === 'create') {
			await api.post('createAdditionalAddress', { userId: userId, ...data })
			}
			if (data?.type === 'update') {
				await api.post('updateAdditionalAddress', { userId: userId, ...data })
			}
			await getCustomerAdditionalAddresses()
		} catch (err) {
			console.log('err', err)
		}
	}, [userId])

	const getCustomerAdditionalAddresses = useCallback(async () => {
		try {
			setIsLoading(true)
			const { data } = await api.get('getUserAdditionalAdresses', { params: { userId } })
			const formData = transformAddressesData(data, countries)

			Object.entries(formData).map(([key, value]) => {
				addressForm.setValue(key, value, {shouldValidate: true})
			})

		} catch (err) {
			console.log('err', err)
		} finally {
			setIsLoading(false)
		}
	}, [userId, countries])

	const deleteAdditionalAddress = useCallback(async (key) => {
		try {
			if (confirm('Do you want to delete the address?')) {
				setIsLoading(true)
				const data = addressForm.getValues(key)
				await api.post('deleteAdditionalAddress', { userId: userId, addressId: data.id })
				addressForm.reset()
				await getCustomerAdditionalAddresses()
			}
		} catch (err) {
			console.log('err', err)
		} finally {
			setIsLoading(false)
		}
	}, [userId])

	const addNewAddress = (e) => {
		e.preventDefault()
		const data = addressForm.getValues()
		const dataKeys = Object.keys(data)
		const length = dataKeys.length
		const lastKey = dataKeys[length - 1] || 0
		const newKey = parseInt(lastKey) + 1
		addressForm.setValue(newKey.toString(), {
			fullname: '',
			phone: '',
			country: '',
			city: '',
			postalCode: '',
			state: '',
			line1: '',
			line2: '',
			type: 'create'
		}, {shouldValidate: true})
	}

	const setDefaultAddress = async (e, key) => {
		try {
			setIsLoading(true)
			const data = addressForm.getValues(key)
			if (e.target.checked) {
				await api.post('setAdressAsDefault', { userId: userId, addressId: data.id })
				await fetchUser()
			}
			await getCustomerAdditionalAddresses()
		} catch (err) {
			console.log('err', err)
		} finally {
			setIsLoading(false)
		}
	}

	const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);
	
	useLayoutEffect(() => {
		api.get('getCountries').then(res => {
			setCountries(res.data)
		})

		if (!userId) {
			getDoc(doc(db, 'Meta', 'settings')).then(data => {
				const skip = data.data()?.skip_intro_questionnaire
				setValue('skipQuestionnaire', skip)
			})
		}
	}, [userId])

	useEffect(() => {
		if (userId && countries.length > 0) {
			getCustomerAdditionalAddresses()
		}
	}, [userId, countries])

	useEffect(() => {
		if (userId) {
			fetchUser()
		}
	}, [userId])

	return (
		<Flex flexDirection='column'>
			<div onClick={goBack} className='back-link'>
				<BackArrow /> {t('portal_customers_new_back_button')}
			</div>
			<h2 className='title'>
				{userId
					? t('portal_customers_edit_top_title')
					: t('portal_customers_new_top_title')}
			</h2>
			<p className='descr'>
				{userId
					? t('portal_customers_edit_top_description')
					: t('portal_customers_new_top_description')}
			</p>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex
					gap='38px'
					mt='30px'
					mb='24px'
					flexDirection={{ lg: 'row', sm: 'column' }}
				>
					<FormControl isInvalid={errors?.firstName}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_first_name_title')}
						</FormLabel>
						<Input
							{...register('firstName')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t('portal_customers_new_first_name_placeholder')}
						/>
						{errors?.firstName && (
							<FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors?.lastName}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_last_name_title')}
						</FormLabel>
						<Input
							{...register('lastName')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t('portal_customers_new_last_name_placeholder')}
						/>
						{errors?.lastName && (
							<FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
						)}
					</FormControl>
				</Flex>
				<Flex gap='38px' mb='24px' flexDirection={{ lg: 'row', sm: 'column' }}>
					<FormControl isInvalid={errors?.email}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_email_title')}
						</FormLabel>
						<Input
							{...register('email')}
							type='email'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t('portal_customers_new_email_placeholder')}
						/>
						{errors?.email && (
							<FormErrorMessage>{errors.email?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors?.gender}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_gender_title')}
						</FormLabel>
						<Select
							{...register('gender')}
							borderColor={'rgba(61, 37, 65, 0.4)'}
							h='44px'
						>
							<option value=''>
								{t('portal_customers_new_gender_placeholder')}
							</option>
							<option value='M'>{t('portal_customers_new_gender_male')}</option>
							<option value='F'>
								{t('portal_customers_new_gender_female')}
							</option>
							<option value='O'>
								{t('portal_customers_new_gender_other')}
							</option>
						</Select>
						{errors?.gender && (
							<FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
						)}
					</FormControl>
				</Flex>
				<Flex gap='38px' mb='24px' flexDirection={{ lg: 'row', sm: 'column' }}>
					<FormControl isInvalid={errors?.notes}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_notes_title')}
						</FormLabel>
						<Textarea
							{...register('notes')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							placeholder={t('portal_customers_new_notes_placeholder')}
						/>
						{errors?.no && (
							<FormErrorMessage>{errors.notes?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors?.language}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_language_title')}
						</FormLabel>
						<Select
							{...register('language')}
							borderColor={'rgba(61, 37, 65, 0.4)'}
							h='44px'
						>
							<option value=''>
								{t('portal_customers_new_language_placeholder')}
							</option>
							<option value='EN'>
								{t('portal_customers_new_language_en')}
							</option>
							<option value='RU'>
								{t('portal_customers_new_language_ru')}
							</option>
						</Select>
						{errors?.language && (
							<FormErrorMessage>{errors.language?.message}</FormErrorMessage>
						)}
					</FormControl>
				</Flex>
				<label className='experts-products-label'>
					Send marketing messages
					<Checkbox
						colorScheme='red'
						isChecked={canSendMarketingMessages}
						onChange={ev => setCanSendMarketingMessages(ev.target.checked)}
					/>
				</label>

				<Text
					mt='46px'
					fontSize='26px'
					fontWeight={600}
					color='#3D2541'
					className='title'
				>
					{t('portal_customers_new_delivery_address_title') + ' (Default)'}
				</Text>

				<Flex gap='38px' my='24px' flexDirection={{ lg: 'row', sm: 'column' }}>
					<FormControl isInvalid={errors?.fullname}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_full_name_title')}
						</FormLabel>
						<Input
							{...register('fullname')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t(
								'portal_customers_new_delivery_full_name_placeholder'
							)}
						/>
						{errors?.fullname && (
							<FormErrorMessage>{errors.fullname?.message}</FormErrorMessage>
						)}
					</FormControl>
				</Flex>
				<Flex gap='38px' my='24px' flexDirection={{ lg: 'row', sm: 'column' }}>
					<FormControl>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_contact_phone_title')}
						</FormLabel>
						<PhoneInput
							country={'gb'}
							countryCodeEditable={false}
							value={phone}
							onChange={value => setPhone(value)}
							inputStyle={{
								background: 'transparent',
								color: '#3D2541',
								height: '44px',
								width: '100%',
								borderRadius: '0.375rem',
								borderColor: 'rgba(61, 37, 65, 0.4)',
							}}
							dropdownClass='phone-input-dropdown'
							buttonClass='phone-input-wrapper'
						/>
						{errors?.phone && (
							<FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl>
						<FormLabel fontSize={'15px'}>
							Additional phone
						</FormLabel>
						<PhoneInput
							country={'gb'}
							countryCodeEditable={false}
							value={additionalPhone}
							onChange={value => setAdditionalPhone(value)}
							inputStyle={{
								background: 'transparent',
								color: '#3D2541',
								height: '44px',
								width: '100%',
								borderRadius: '0.375rem',
								borderColor: 'rgba(61, 37, 65, 0.4)',
							}}
							dropdownClass='phone-input-dropdown'
							buttonClass='phone-input-wrapper'
						/>
						{errors?.additionalPhone && (
							<FormErrorMessage>{errors.additionalPhone?.message}</FormErrorMessage>
						)}
					</FormControl>
				</Flex>
				<Flex gap='38px' mb='24px' flexDirection={{ lg: 'row', sm: 'column' }}>
					<FormControl isInvalid={errors?.country}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_country_title')}
						</FormLabel>
						<Select
							{...register('country')}
							borderColor={'rgba(61, 37, 65, 0.4)'}
							h='44px'
						>
							<option value=''>
								{t('portal_customers_new_delivery_country_placeholder')}
							</option>
							{countries.map(i => (
								<option key={i.code} value={i.code}>
									{i.name}
								</option>
							))}
						</Select>
						{errors?.country && (
							<FormErrorMessage>{errors.country?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors?.city}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_city_title')}
						</FormLabel>
						<Input
							{...register('city')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t('portal_customers_new_delivery_city_placeholder')}
						/>
						{errors?.city && (
							<FormErrorMessage>{errors.city?.message}</FormErrorMessage>
						)}
					</FormControl>
				</Flex>
				<Flex gap='38px' mb='24px' flexDirection={{ lg: 'row', sm: 'column' }}>
					<FormControl isInvalid={errors?.postal_code}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_postcode_title')}
						</FormLabel>
						<Input
							{...register('postal_code')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t(
								'portal_customers_new_delivery_postcode_placeholder'
							)}
						/>
						{errors?.postal_code && (
							<FormErrorMessage>{errors.postal_code?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors?.state}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_state_province_title')}
						</FormLabel>
						<Input
							{...register('state')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t(
								'portal_customers_new_delivery_state_province_placeholder'
							)}
						/>
						{errors?.state && (
							<FormErrorMessage>{errors.state?.message}</FormErrorMessage>
						)}
					</FormControl>
				</Flex>
				<Flex gap='38px' mb='30px' flexDirection={{ lg: 'row', sm: 'column' }}>
					<FormControl isInvalid={errors?.line1}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_street_title')}
						</FormLabel>
						<Input
							{...register('line1')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t(
								'portal_customers_new_delivery_street_placeholder'
							)}
						/>
						{errors?.line1 && (
							<FormErrorMessage>{errors.line1?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors?.line2}>
						<FormLabel fontSize={'15px'}>
							{t('portal_customers_new_delivery_appartment_title')}
						</FormLabel>
						<Input
							{...register('line2')}
							type='text'
							borderColor={'rgba(61, 37, 65, 0.4)'}
							height='44px'
							placeholder={t(
								'portal_customers_new_delivery_appartment_placeholder'
							)}
						/>
					</FormControl>
				</Flex>
				
				{!userId && (
					<Flex justifyContent={'flex-end'} mb='25px'>
						<label className='switch'>
							{t('portal_customers_new_questionnaire_switcher_title')}
							<input type='checkbox' {...register('skipQuestionnaire')} />
							<span />
						</label>
					</Flex>
				)}

				{errorMessage && (
					<Text fontSize='sm' textAlign={'right'} mb='20px' color='red.300'>
						{errorMessage}
					</Text>
				)}

				<Flex justifyContent={'flex-end'}>
					<Flex gap={3}>
						{(isEmptyObject(addressForm.getValues()) && pathname !== '/admin/customers/new') && <button
							className='custom-btn'
							onClick={addNewAddress}
						>
							new address
						</button>}
						<button
							type='submit'
							disabled={createIsLoading}
							className='custom-btn'
						>
							{createIsLoading
								? t('portal_loading')
								: userId
								? t('portal_customers_edit_bottom_button_title')
								: t('portal_customers_new_bottom_button_title')}
						</button>
					</Flex>
				</Flex>
			</form>
			<FormProvider {...addressForm}>
					<form onSubmit={(e)=>{e.preventDefault()}}>
						<AddressForm 
							countries={countries} 
							onSendAddress={sendAdditionalAddress}
							onAddAddress={addNewAddress}
							onDeleteAddress={deleteAdditionalAddress}
							onSetDefaultAddress={setDefaultAddress}
							isLoading={isLoading}
						/>
					</form>
				</FormProvider>
		</Flex>
	)
}

export default ExpertsUsersNew
