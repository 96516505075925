import axios from 'axios'
import { getAuth } from 'firebase/auth'

const API_URL = process.env.REACT_APP_CENTRAL_API_URL

const centralApi = axios.create({
  baseURL: API_URL
})

centralApi.interceptors.request.use(
  async config => {
    if (config.headers) {
      const auth = getAuth()
      const token = await auth.currentUser.getIdToken(true)
      config.headers.Authorization = `Bearer ${token}`
      // config.headers['x-libi-api-key'] = '4eb53a13-5f2e-4deb-92d7-090a4b53fd47'
    }
    if(!config.params) {
      config.params = {};
    }
    const swithedUser = JSON.parse(localStorage.getItem("switched_user"));
    if (swithedUser) {
        config.params.userId = swithedUser.id;
    }
    return config
  },
  error => {
    return Promise.reject(error);
  },
)

export default centralApi