import { useState, useCallback, memo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Flex, useToast } from "@chakra-ui/react";
import PropTypes from "prop-types";

import api from "api";
import centralApi from "centralApi";
import { BackArrow } from "components/Icons/Icons";

const Header = ({ shipmentData, orderId, setShipmentData, expertId }) => {
  const [shipmentIsDeleting, setShipmentIsDeleting] = useState(false);
  const [orderIsDeleting, setOrderIsDeleting] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();

  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  const onCreateShipment = useCallback(() => {
    history.push(`/admin/orders/shipment/${orderId}`);
  }, [orderId]);

  const onCancelShipment = useCallback(async () => {
    if (confirm("Do you really want to cancel shipment?")) {
      setShipmentIsDeleting(true);
      try {
        await centralApi.get(`shipment/${orderId}/cancel`);
        setShipmentData(null);
      } catch (err) {
        console.log("err", err);
      } finally {
        setShipmentIsDeleting(false);
      }
    }
  }, [orderId, setShipmentData]);

  const onDeleteOrder = useCallback(async () => {
    if (confirm("Do you really want to delete this order?")) {
      setOrderIsDeleting(true);
      try {
        await api.post("deleteOrder", { id: orderId });

        const storageOrders = JSON.parse(
          localStorage.getItem(`orders_${expertId}`)
        );
        const filteredOrders = storageOrders.filter((i) => i.id !== orderId);

        localStorage.setItem(
          `orders_${expertId}`,
          JSON.stringify(filteredOrders)
        );

        if (history.length > 1) {
          history.goBack();
        } else {
          window.close();
        }
      } catch (err) {
        toast({
          title: err?.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } finally {
        setOrderIsDeleting(false);
      }
    }
  }, [orderId, expertId]);

  return (
    <>
      <div onClick={goBack} className="back-link">
        <BackArrow /> {t("portal_orders_view_back")}
      </div>
      <Flex justifyContent="space-between">
        <div>
          <h2 className="title">{t("portal_orders_view_top_title")}</h2>
          <p className="descr">{t("portal_orders_view_top_description")}</p>
        </div>
        {!shipmentData && (
          <button className="custom-link" onClick={onCreateShipment}>
            {t("portal_orders_view_create_shipment", "Create Shipment")}
          </button>
        )}
        {shipmentData && (
          <button
            className="custom-link"
            onClick={onCancelShipment}
            disabled={shipmentIsDeleting}
          >
            {t("portal_orders_view_cancel_shipment", "Cancel Shipment")}
          </button>
        )}
        <button
          className="custom-link"
          onClick={onDeleteOrder}
          disabled={orderIsDeleting}
        >
          {t(
            orderIsDeleting ? "portal_order_is_deleting" : "portal_delete_order"
          )}
        </button>
      </Flex>
    </>
  );
};

Header.propTypes = {
  orderId: PropTypes.string,
  expertId: PropTypes.string,
  shipmentData: PropTypes.object,
  setShipmentData: PropTypes.func,
};

export default memo(Header);
