import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count: 0,
    nextCall: 0,
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotificationCount(state, action) {
            state.count = action.payload;
        },
        makeNextCall(state) {
            state.nextCall = state.nextCall + 1;
        }
    },
});

export const { setNotificationCount, makeNextCall } = notificationSlice.actions;

export default notificationSlice.reducer;
