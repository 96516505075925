import { Flex, Spinner, Text } from '@chakra-ui/react';
import { db } from '../../../firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useFlexLayout, useTable } from 'react-table';
import { BackArrow } from 'components/Icons/Icons';
import api from 'api';

const columns = [
  {
      Header: "Created",
      accessor: "time",
      Cell: ({ value }) => value 
        ? <span title={moment(value?.toDate()).format("HH:mm MMM DD YYYY")}>
            {moment(value?.toDate()).format("DD/MM/YYYY")}
          </span> 
        : "-",
      width: 70,
      maxWidth: 70,
      minWidth: 70,
      sortType: (a, b) => {
        if (a.values.time?.seconds < b.values.time?.seconds) {
            return -1;
        } else if (a.values.time?.seconds > b.values.time?.seconds) {
            return 1;
        };

        return 0;
      },
      
  },
  {
      Header: "Name",
      accessor: "name",
  },
  {
      Header: "Email",
      accessor: "email",
  }
];

const CommissionsDetail = () => {
  const { id } = useParams()

  const [payment, setPayment] = useState({})
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: orders,
    },
    useFlexLayout
  );


  const fetchData = async () => {
    try {
      setIsLoading(true)

      const ordersArr = []
      const {data: expertPayments} = await api.get('getExpertPayments')
      const data = expertPayments?.find(i => i.id === id)

      setPayment(data)

      if(data.order_ids) {
        const orderRef = collection(db, "Orders")
        const orderSnap = await getDocs(orderRef)
        orderSnap.forEach(async (orderDoc) => {
          if(data.order_ids.includes(orderDoc.id)) {
            const dataObj = orderDoc.data();
            let obj = {
              id: orderDoc.id,
              ...dataObj
            }
            
            const usersRef = doc(db, "Users", dataObj.userId)
            const userSnap = await getDoc(usersRef)
            if(userSnap.exists()) {
              obj = {
                ...obj,
                ...userSnap.data()
              }
            }
            
            ordersArr.push(obj)
          }
        })

        const interval = setInterval(() => {
          if(ordersArr?.length) {
            setOrders(ordersArr.sort((a,b) => a.time?.seconds < b.time?.seconds ? 1 : -1))
            setIsLoading(false)
            clearInterval(interval)
          }
        }, 100)
      } else {
        setIsLoading(false)
      }
      
    } catch(err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Flex direction="column">
      <Link to="/admin/commissions" className='back-link'>
          <BackArrow/> Back
      </Link>
      {/* TODO: add to language file all the strings below */}
      <h2 className='title'>Commissions Detail</h2>
      <p className='descr'>Commissions Detail</p>

      {isLoading && <Flex mx={"auto"} mt="20px"><Spinner color="red.700" size="xl" /></Flex>}
      {!isLoading && isError && <p className='error'>{t('portal_error_message')}</p>}
      {!isLoading && !isError ? (
        <div>
          <hr className='hr' />
          <Text 
            fontSize={15} 
            color={'#3D2541'} 
            mb={'20px'}
          ><b>Created:</b>&nbsp; {moment(payment?.time?.toDate()).format("DD/MM/YYYY")}</Text>
          <Text 
            fontSize={15} 
            color={'#3D2541'} 
            mb={'20px'}
          ><b>Price:</b>&nbsp; {(payment.price / 100).toFixed(2)} GBP</Text>
          <Text 
            fontSize={15} 
            color={'#3D2541'} 
            mb={'20px'}
          ><b>Taxes:</b>&nbsp; {(payment.taxes / 100).toFixed(2)} GBP</Text>
          <Text 
            fontSize={15} 
            color={'#3D2541'} 
            mb={'20px'}
          ><b>Total:</b>&nbsp; {(payment.total / 100).toFixed(2)} GBP</Text>
          <hr className='hr' />
          <h2 className='title'>Assessment forms</h2>
          {/* <Text fontSize='lg' color={textColor} fontWeight='bold' mt="2rem" mb="1rem">Assessment forms</Text> */}

          {orders?.length > 0 ? (
            <div
              className='table'
              {...getTableProps()}
            >
              <div className='thead'>
                {headerGroups.map((headerGroup) => (
                  <div className='tr' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <div
                        className='th'
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className='tbody' {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <div className='tr' {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <div className='td' {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : <p className='error'>Nothing to display</p>}
        </div>
      ) : null}
    </Flex>
  )
}

export default CommissionsDetail