// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
    Button,
    Flex,
    useToast,
    Menu,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import { ReactComponent as NotificationIcon } from "assets/svg/notification.svg"
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import { useSelector, useDispatch } from "react-redux";
import { setNotificationCount } from "store/slices/notificationSlice";
import { getAuth } from "firebase/auth";
import { useTranslation } from "react-i18next";
import centralApi from "centralApi";

const useGetNotificationInterval = () => {
    const timeout = useRef(null);
    const abortControllerRef = useRef(null);
    const dispatch = useDispatch();
    const createToast = useToast();
    const { count, nextCall } = useSelector(state => state.notification);

    useEffect(() => {
        const getNew = async () => {
            try {
                const { data } = await centralApi.get("notifications/new");
                if(data.count > 0) {
                    dispatch(setNotificationCount(data.count));
                }
            } catch(err) {
                console.log(err)
            }
        }

        const getUpdates = async () => {
            try {
                const abortController = new AbortController();
                abortControllerRef.current = abortController;

                const { data } = await centralApi.get("notifications/subscribe", {
                    signal: abortController.signal,
                });
                if (data.received) {
                    dispatch(setNotificationCount(data.count));
                    let notifText = data.count === 1 ? "New notification" : `${data.count} New notifications`
                    createToast({
                        title: notifText,
                        position: "top-right",
                        isClosable: true,
                        status: "success",
                    });
                }
                timeout.current = setTimeout(() => {
                    getUpdates();
                }, 100);
            } catch(err) {
                console.log(err)
            }
        };

        getNew();
        getUpdates();
        return () => {
            abortControllerRef.current.abort();
            clearTimeout(timeout.current);
        };
    }, [nextCall]);

    return count;
};

export default function HeaderLinks(props) {
    const { t } = useTranslation();
    const { variant, children, fixed, secondary, onOpen, ...rest } = props;
    const { avatar } = useSelector(state => state.user);
    const auth = getAuth();

    // Chakra Color Mode
    let mainTeal = useColorModeValue("red.300", "red.300");
    let inputBg = useColorModeValue("white", "gray.800");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarIcon = useColorModeValue("gray.500", "gray.200");
    let searchIcon = useColorModeValue("gray.700", "gray.200");

    const notificationCounter = useGetNotificationInterval();
    const createTestNotification = useCallback(async () => {
        await centralApi.post("notifications/add", {});
    }, [])

    if (secondary) {
        navbarIcon = "white";
        mainText = "white";
    }
    const settingsRef = React.useRef();
    return (
        <Flex
            w={{ sm: "100%", md: "auto" }}
            alignItems="center"
            flexDirection="row"
        >
            {/* <InputGroup
                cursor="pointer"
                bg={inputBg}
                borderRadius="15px"
                w={{
                    sm: "128px",
                    md: "200px",
                }}
                me={{ sm: "auto", md: "20px" }}
                _focus={{
                    borderColor: { mainTeal },
                }}
                _active={{
                    borderColor: { mainTeal },
                }}
            >
                <InputLeftElement
                    children={
                        <IconButton
                            bg="inherit"
                            borderRadius="inherit"
                            _hover="none"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                            icon={
                                <SearchIcon
                                    color={searchIcon}
                                    w="15px"
                                    h="15px"
                                />
                            }
                        ></IconButton>
                    }
                />
                <Input
                    fontSize="xs"
                    py="11px"
                    color={mainText}
                    placeholder="Type here..."
                    borderRadius="inherit"
                />
            </InputGroup> */}
            {auth.currentUser.email ? (
                <>
                    {
                        notificationCounter > 0 && (
                            <NavLink to="/admin/notifications">
                                <div className="notification-counter-wrapper">
                                    <NotificationIcon />
                                    <span>{notificationCounter > 99 ? "99+" : notificationCounter}</span>
                                </div>
                            </NavLink>
                        )
                    }
                    {/* {
                        process.env.NODE_ENV === 'development' && <div onClick={createTestNotification} className="dev-create-notif">new notification</div>
                    } */}
                    <NavLink to="/auth/profile">
                        <span className="header-profile">
                            {t('portal_go_profile')}
                            {avatar && 
                                <img 
                                    src={avatar} 
                                    alt="Avatar" 
                                />
                            }
                        </span>
                    </NavLink>
                </>
            ) : (
                <NavLink to="/auth/signin">
                    <Button
                        ms="0px"
                        px="0px"
                        me={{ sm: "2px", md: "16px" }}
                        color={navbarIcon}
                        variant="transparent-with-icon"
                        rightIcon={
                            document.documentElement.dir ? (
                                ""
                            ) : (
                                <ProfileIcon
                                    color={navbarIcon}
                                    w="22px"
                                    h="22px"
                                    me="0px"
                                />
                            )
                        }
                        leftIcon={
                            document.documentElement.dir ? (
                                <ProfileIcon
                                    color={navbarIcon}
                                    w="22px"
                                    h="22px"
                                    me="0px"
                                />
                            ) : (
                                ""
                            )
                        }
                    >
                        <Text display={{ sm: "none", md: "flex" }}>
                            Sign in
                        </Text>
                    </Button>
                </NavLink>
            )}
            {/* <SidebarResponsive
                logoText={props.logoText}
                secondary={props.secondary}
                routes={routes}
                // logo={logo}
                {...rest}
            /> */}
            {/* <SettingsIcon
                cursor="pointer"
                ms={{ base: "16px", xl: "0px" }}
                me="16px"
                ref={settingsRef}
                onClick={props.onOpen}
                color={navbarIcon}
                w="18px"
                h="18px"
            /> */}
            <Menu>
                {/* <MenuButton>
                    <BellIcon color={navbarIcon} w="18px" h="18px" />
                </MenuButton> */}
                <MenuList p="16px 8px">
                    <Flex flexDirection="column">
                        <MenuItem borderRadius="8px" mb="10px">
                            <ItemContent
                                time="13 minutes ago"
                                info="from Alicia"
                                boldInfo="New Message"
                                aName="Alicia"
                                aSrc={avatar1}
                            />
                        </MenuItem>
                        <MenuItem borderRadius="8px" mb="10px">
                            <ItemContent
                                time="2 days ago"
                                info="by Josh Henry"
                                boldInfo="New Album"
                                aName="Josh Henry"
                                aSrc={avatar2}
                            />
                        </MenuItem>
                        <MenuItem borderRadius="8px">
                            <ItemContent
                                time="3 days ago"
                                info="Payment succesfully completed!"
                                boldInfo=""
                                aName="Kara"
                                aSrc={avatar3}
                            />
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
};
