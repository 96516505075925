import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Grid, GridItem, Spinner, Text, useToast } from '@chakra-ui/react'
import api from 'api'
import {
  api1,
  api2,
  API_URL_1,
  API_URL_2,
  API_TOKEN_1,
  API_TOKEN_2,
} from 'apiStore'
import classNames from 'classnames'
import Checkbox from 'components/Checkbox'
import { ArrowNext, BackArrow, CloseIconSimple, EditIconSimple, PlusCircle, TrashIcon } from 'components/Icons/Icons'
import RequestQuestionnaireBtn from 'components/RequestQuestionnaireBtn'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { chunk, fileSizeIsValid } from 'helpers'
import moment from 'moment'
import cn from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { db } from '../../../firebase'

const pageLimit = 5

const Pagination = ({ totalPages, currentPage, setPage }) => {
  return (
    <div className='pagination'>
      {Array.from({ length: totalPages }, (i, index) => (
        <div
          key={index}
          className={classNames('pagination-page', { 'pagination-page-active': currentPage === index })}
          onClick={() => setPage(index)}
        >
          {index + 1}
        </div>
      ))}
      {(currentPage !== totalPages - 1) &&
        <ArrowNext
          className="pagination-next"
          onClick={() => setPage(currentPage + 1)}
        />
      }
    </div>
  )
}

const ExpertsUserInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams()
  const history = useHistory()

  const [userData, setUserData] = useState({})
  const [afs, setAfs] = useState([])
  const [afsCurrPage, setAfsCurrPage] = useState(0)
  const [orders, setOrders] = useState([])
  const [ordersCurrPage, setOrdersCurrPage] = useState(0)
  const [answers, setAnswers] = useState([])
  const [answersCurrPage, setAnswersCurrPage] = useState(0)
  const [attachments, setAttachments] = useState([])
  const [attachmentsCurrPage, setAttachmentsCurrPage] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isPerform, setIsPerform] = useState(true)
  const [attachmentIsUploading, setAttachmentIsUploading] = useState(false)
  const [countries, setCountries] = useState([])
  const [mailgunStats, setMailgunStats] = useState([])
  const [mailgunStatsCurrPage, setMailgunStatsCurrPage] = useState(0)
  const [editFile, setEditFile] = useState(null)
  const [editedFilename, setEditedFilename] = useState(null)
  const [additionalAddresses, setAdditionalAddresses] = useState([])

  const toast = useToast()

  const fileTypeCheck = (file) => {
    const extension = (''+file).split('.').pop();
    return (extension == 'png' || extension == 'jpg' || extension == 'jpeg') ? "image" : extension;
  }

  const changeQuestionnaire = () => {
    api.post('updateUser', { id, skipQuestionnaire: !userData.skipQuestionnaire }).then(() => {
      setUserData(prev => ({
        ...prev,
        skipQuestionnaire: !prev.skipQuestionnaire
      }))
    })
  }

  const addAttachment = async (ev) => {
    setAttachmentIsUploading(true)

    const files = Array.from(ev.target.files)

    await new Promise((resolve, reject) => {
      files.forEach(file => {
        if (fileSizeIsValid(file.size, 50)) {
          const storage = getStorage();
          const fileRef = ref(storage, `attachments/${file.name}`);
    
          uploadBytes(fileRef, file).then(() => {
            getDownloadURL(fileRef)
              .then(async (url) => {
                const fileObj = {
                  name: file.name,
                  url,
                  time: new Date()
                }
    
                const {data} = await api.post('createAttachment', {
                  user_id: id,
                  ...fileObj
                })
                setAttachments(prev => chunk([
                  {
                    ...fileObj,
                    id: data.insertId,
                    time: moment(Date.now()).format("DD/MM/YYYY")
                  },
                  ...prev.flat(),
                ], pageLimit))  

                resolve()
              })
          });
        } else {
          toast({
            title: 'Max file size is 50mb',
            position: 'top-right',
            isClosable: true,
            status: 'error'
          })
          resolve()
        }
      })
    })

    setAttachmentIsUploading(false)
  }

  const updateAttachment = async (fileName, fileId) => {
    try {
      setAttachmentIsUploading(true)
      const currentFile = attachments.flat().find(i => i.id === fileId)
      await api.post('updateAttachment', {...currentFile, name: fileName})
      await getAttachments(id)
      setEditFile(null)
    } catch (error) {
      toast({
        title: 'Something went wrong',
        position: 'top-right',
        isClosable: true,
        status: 'error'
      })
    } finally {
      setAttachmentIsUploading(false)
    }
  }

  const removeAttachment = async (fileName, fileId) => {
    if (confirm('Do you really want to delete this file?')) {
      const storage = getStorage();

      const fileRef = ref(storage, `attachments/${fileName}`);

      deleteObject(fileRef)
      
      try {
        await api.post('deleteAttachment', {id: fileId})
  
        const newAttachments = attachments.flat().filter(i => i.id !== fileId)
        setAttachments(chunk(newAttachments, pageLimit))
      } catch(err) {
        toast({
          title: 'Something went wrong',
          position: 'top-right',
          isClosable: true,
          status: 'error'
        })
      }
    }
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)

      api.get('getCountries').then(res => setCountries(res.data))

      const {data: mailgunStats} = await api.get('getMailgunStatsByUserId', {params: {user_id: id}})
      setMailgunStats(chunk(mailgunStats, pageLimit))

      const { data: userByID } = await api.get('userByID', {params: {userId: id}})

      if (userByID) {
        setUserData(userByID)

        const performTime = userByID?.weeklyQuestionnaire
        const performDiff = moment().diff(moment(performTime), 'hours')

        if (!performTime || performDiff >= 24) {
          setIsPerform(false)
        }
      }

      let afArr = []
      const afRef = collection(db, "AssessmentForms")
      const afQuery = query(afRef, where("userId", "==", id))
      const afDocs = await getDocs(afQuery)

      afDocs.forEach(async doc => {
        afArr.push({ id: doc.id, ...doc.data() })
      })

      afArr = afArr?.sort((a, b) => b?.time?.seconds - a?.time?.seconds)

      const { data: ordersByUserID } = await api.get('ordersByUserID', {params: {userId: id}})

      for (let i = 0; i < afArr.length; i++) {
        const af = afArr[i];

        const order = ordersByUserID?.find(j => j.assessmentId === af.id)
        
        if(order) {
          afArr[i] = { ...af, orderId: order.id }
        }
      }

      setAfs(chunk(afArr, pageLimit))

      const sortedOrders = ordersByUserID?.filter(i => !i?.deleted)

      setOrders(chunk(sortedOrders, pageLimit))


      let {data: answersArr} = await api.get('getAnswersByUserId', {params: {userId: id}})
      
      answersArr = answersArr?.sort((a, b) => new Date(b?.time).getTime() - new Date(a?.time).getTime()) || []
      
      setAnswers(chunk(answersArr, pageLimit))

      await getAttachments(id)

    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const getAttachments = async (userId) => {
    let {data: attachmentsArr} = await api.get('getAttachmentsByUserId', {params: {userId}})
      
      attachmentsArr = attachmentsArr?.sort((a, b) => new Date(b?.time).getTime() - new Date(a?.time).getTime()) || []

      setAttachments(chunk(attachmentsArr, pageLimit))
  }

  const getCustomerAdditionalAddresses = useCallback(async () => {
		try {
			const { data } = await api.get('getUserAdditionalAdresses', { params: { userId: id } })
			const addressesData = data
        .filter(address => !address?.isDefault)
        .map(item => ({
          ...item,
          country: countries.find(country => country.id === item.country_id)?.name || item.country_id
        }))
      setAdditionalAddresses(addressesData)
		} catch (err) {
			console.log('err', err)
		}
	}, [id, countries])

  const setDefaultAddress = async (addressId) => {
		try {
			setIsLoading(true)
			const data = additionalAddresses.find((address) => address.id === addressId)
      console.log('data', data);
			if (!!data) {
				await api.post('setAdressAsDefault', { userId: id, addressId: data.id })
				await fetchData()
			}
			await getCustomerAdditionalAddresses()
		} catch (err) {
			console.log('err', err)
		} finally {
			setIsLoading(false)
		}
	}

  const setAccountType = (accountType) => async () => {
    if(isLoading) return;
		try {
      setIsLoading(true);
      if(API_URL_1) {
        try {
          await api1.post("customers/account-type", {
            accountType,
            email: userData.email,
            token: API_TOKEN_1,
          });
        } catch (error) {
          console.log('err', error);
        }
      }
      if(API_URL_2) {
        try {
          await api2.post("customers/account-type", {
            accountType,
            email: userData.email,
            token: API_TOKEN_2,
          })
        } catch (error) {
          console.log('err', error);
        }
      }
      await api.post('updateUser', {
        id: userData.id,
        accountType,
      });
      setUserData({
        ...userData,
        accountType,
      });
      toast({
        title: `Account type has been changed to ${accountType}`,
        position: "top-right",
        isClosable: true,
        status: "success",
      });
		} catch (err) {
			console.log('err', err)
		} finally {
			setIsLoading(false)
		}
	}

  const goBack = useCallback(() => {
    if (history.length > 1) {
      console.log(history)
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  useEffect(() => {
    const getData = async () => {
      await fetchData()
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (countries.length !== 0) {
        await getCustomerAdditionalAddresses()
      }
    }
    getData()
  }, [countries])

  const accountTypeButtonStyles = cn('custom-link', {
    ['custom-link-disabled']: isLoading,
  })

  return (
    <Flex flexDirection="column">
      <div onClick={goBack} className='back-link'>
        <BackArrow /> {t('portal_customers_view_back_button')}
      </div>
      <Flex 
        alignItems={{md: "center", base: 'flex-start'}} 
        justifyContent="space-between" 
        flexDirection={{
            base: "column",
            md: "row",
        }}
        gap={{base: '10px', md: '0'}}
      >
        <div>
          <h2 className="title">{t('portal_customers_view_top_title')}</h2>
          <p className="descr">{t('portal_customers_view_top_description')}</p>
        </div>
        <Flex
          flexDirection="row"
          gap={{ xl: "32px", lg: '16px' }}
          alignItems={{ xl: "center", lg: 'flex-end' }}
        >
          {
            userData?.id && userData?.accountType !== "family" &&
              <Text
                className={accountTypeButtonStyles}
                marginRight="4"
                onClick={setAccountType("family")}
              >
                {t('portal_customers_view_make_family_button', 'Switch To Family Account')}
              </Text>
          }
          {
            userData?.id && userData?.accountType === "family" &&
              <Text
                className={accountTypeButtonStyles}
                marginRight="4"
                onClick={setAccountType("personal")}
              >
                {t('portal_customers_view_make_personal_button', 'Switch To Personal Account')}
              </Text>
          }
          <Link to={`/admin/customers/edit/${id}`} className='custom-link'>
            {t('portal_customers_view_edit_button')}
          </Link>
          {/* <button className='outlined-btn'>{t('portal_customers_view_questionnaire_button')}</button> */}
        </Flex>
      </Flex>

      <hr className='hr' />

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && <p className='error'>{t('portal_error_message')}</p>}
      {!isLoading && !isError &&
        <div>
          <Flex
            flexDirection={{ md: 'row', sm: 'column-reverse' }}
            gap={{ md: "32px", sm: '16px' }}
            alignItems="center"
            justifyContent={'space-between'}
            mb="40px"
            h={{md: 49, base: 'auto'}}
          >
            <label className='switch'>
              {t('portal_customers_view_questionnaire_switcher_title')}
              <input
                type="checkbox"
                checked={userData?.skipQuestionnaire}
                onChange={changeQuestionnaire}
              />
              <span />
            </label>
            <RequestQuestionnaireBtn
              userData={userData}
              setMailgunStats={(value) => setMailgunStats(chunk(value, pageLimit))}
            />
          </Flex>
          <Flex justifyContent={"space-between"} flexDirection={{ lg: 'row', sm: 'column' }} flexWrap={"wrap"}>
            <div>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_first_name_title')}:</b>&nbsp; {userData?.firstName || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_email_title')}:</b>&nbsp; {userData?.email || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_account_type_title', 'Account Type')}:</b>&nbsp; {userData?.accountType || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_full_name_title')}:</b>&nbsp;
                {userData?.shippingAddress?.fullname || '-'}
              </Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_country_title')}:</b>&nbsp; {countries.find(i => i.code === userData?.shippingAddress?.country)?.name || userData?.shippingAddress?.country || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_postcode_title')}:</b>&nbsp; {userData?.shippingAddress?.postal_code || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_street_title')}:</b>&nbsp; {userData?.shippingAddress?.line1 || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_appartment_title')}:</b>&nbsp; {userData?.shippingAddress?.line2 || '-'}</Text>
            </div>
            <div style={{ width: '440px' }}>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_last_name_title')}:</b>&nbsp; {userData?.lastName || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_gender_title')}: </b>&nbsp;
                {!userData?.gender && '-'}
                {(userData?.gender === 'male' || userData?.gender === 'M') && t('portal_customers_view_gender_male')}
                {(userData?.gender === 'female' || userData?.gender === 'F') && t('portal_customers_view_gender_female')}
                {(userData?.gender === 'other' || userData?.gender === 'O') && t('portal_customers_view_gender_other')}
              </Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_language_title')}: </b>&nbsp;
                {!userData?.language && '-'}
                {(userData?.language === 'RU') && t('portal_customers_view_language_ru')}
                {(userData?.language === 'EN' ) && t('portal_customers_view_language_en')}
              </Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_contact_phone_title')}:</b>&nbsp; {userData?.phone || userData?.shippingAddress?.phone || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>Additional phone:</b>&nbsp; {userData?.additional_phone.length > 7 ? userData?.additional_phone : '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_city_title')}:</b>&nbsp; {userData?.shippingAddress?.city || '-'}</Text>
              <Text
                fontSize={15}
                color={'#3D2541'}
                mb={'20px'}
              ><b>{t('portal_customers_view_delivery_state_province_title')}:</b>&nbsp; {userData?.shippingAddress?.state || '-'}</Text>
            </div>
            <div style={{ width: '100%' }}>
              <Text
                  fontSize={15}
                  color={'#3D2541'}
                  mb={'20px'}
                ><b>{t('portal_customers_view_notes_title')}:</b>&nbsp; <span style={{ display: 'flex', width: 440, height: !userData?.notes ? 'auto' : 80, overflowY: 'auto'}}>
                  {userData?.notes || '-'}</span></Text>
            </div>
          </Flex>
          <Flex>
            <label className='experts-products-label'>
              Send marketing messages
              <Checkbox
                disabled
                checked={Boolean(userData?.canSendMarketingMessages)}
              />
            </label>
          </Flex>
          {additionalAddresses.length > 0 && <Flex flexDirection='column'>
          <h3 className="title" style={{ fontSize: '26px' }}>Additional Addresses</h3>
            <Accordion allowToggle w='100%'>
              {additionalAddresses
                .map((address, index) => (
                    <AccordionItem key={index}>
                      <AccordionButton
                        _focus={{ boxShadow: '0 0 0 3px rgba(146,0,26, 0.3)' }}
                      >
                        <Box as="span" flex='1' textAlign='left'>
                          {`Address #${index + 1}`}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>Full Name:</b>&nbsp; {address?.fullname || '-'}
                            </Text>
                          </GridItem>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>Contact phone:</b>&nbsp; {address?.phone || '-'}
                            </Text>
                          </GridItem>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>Country:</b>&nbsp; {address?.country || '-'}
                            </Text>
                          </GridItem>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>City:</b>&nbsp; {address?.city || '-'}
                            </Text>
                          </GridItem>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>Postcode:</b>&nbsp; {address?.postal_code || '-'}
                            </Text>
                          </GridItem>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>State or Province:</b>&nbsp; {address?.state || '-'}
                            </Text>
                          </GridItem>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>Address:</b>&nbsp; {address?.line1 || '-'}
                            </Text>
                          </GridItem>
                          <GridItem w='100%'>
                            <Text
                              fontSize={15}
                              color={'#3D2541'}
                            >
                              <b>Apartment:</b>&nbsp; {address?.line2 || '-'}
                            </Text>
                          </GridItem>
                        </Grid>
                        <Button 
                          backgroundColor='#92001a' 
                          color='#fff' size='md'
                          mt={3}
                          style={{ textTransform: 'uppercase', fontSize: '14px'}}
                          _hover={{backgroundColor: '#750117'}} 
                          _active={{backgroundColor: '#750117'}} borderRadius='6px'
                          onClick={() => setDefaultAddress(address?.id)}
                        >
                            set as default address
                        </Button>
                      </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
          </Flex>}
          <Flex 
            mt="26px" 
            justifyContent="space-between"
            alignItems={{md: "center", base: 'flex-start'}} 
            flexDirection={{
                base: "column",
                md: "row",
            }}
            gap={{base: '10px', md: '0'}}
          >
            <h3 className="title" style={{ fontSize: '26px' }}>{t('portal_customers_view_orders_assessments_title')}</h3>
            <Link to={`/admin/orders/new/${id}`} className='custom-btn'>{t('portal_customers_view_create_order_button')}</Link>
          </Flex>

          <hr className='hr' />

          <Flex
            justifyContent="space-between"
            flexDirection={{ lg: 'row', sm: 'column' }}
            gap={{ lg: '40px', sm: '20px' }}
          >
            <Box flex={{ lg: '1', sm: 'none' }}>
              <h3 className='title' style={{ fontSize: '26px', color: '#3D2541', marginBottom: '20px' }}>{t('portal_customers_view_orders_table_title')}</h3>
              {orders?.length > 0 ?
                <>
                  {orders[ordersCurrPage].map(i => (
                    <div
                      key={i?.id}
                      className="download-item download-item-user"
                    >
                      <div className='download-item-id'>{i?.ref ? i.ref.replace('LD', '') : '-'}</div>
                      {moment(i?.created).format("DD/MM/YYYY")}
                      {(!i?.type || i?.type === 'unknown') ?
                        <span>Unknown</span> :
                        <Link to={
                          `/admin/order/${i?.id}${(i?.type.includes('qc'))
                            ? '?type=qc'
                            : i?.type === 'ds'
                              ? '?type=ds'
                              : i?.type === 'se'
                                ? '?type=se'
                                : i?.type === 'st'
                                  ? '?type=st'
                                  : i?.type === 'ip'
                                    ? '?type=ip'
                                    : '?type=rb&tab=products'
                          }`
                        }>
                          <span>
                            {(i?.type.includes('qc') || i?.type === 'ds' || i?.type === 'se' || i?.type === 'st')
                              ? t('portal_customers_view_qc')
                              : i?.type === 'ip' 
                                ? t('portal_orders_new_products_choise_4')
                                : t('portal_orders_new_products_choise_1')
                            }
                          </span>
                        </Link>
                      }
                    </div>
                  ))}
                  {orders.length > 1 && (
                    <Pagination
                      totalPages={orders.length}
                      currentPage={ordersCurrPage}
                      setPage={setOrdersCurrPage}
                    />
                  )}
                </>
                :
                <p className='error' style={{ margin: 0 }}>{t('portal_customers_view_empty')}</p>
              }
            </Box>
            <Box flex={{ lg: '1', sm: 'none' }}>
              <h3 className='title' style={{ fontSize: '26px', color: '#3D2541', marginBottom: '20px' }}>{t('portal_customers_view_af_table_title')}</h3>
              {afs?.length > 0 ?
                <>
                  {afs[afsCurrPage].map(i => (
                    <div
                      key={i.id}
                      className="download-item download-item-user"
                    >
                      {console.log(i.orderId)}
                      {console.log(orders.reduce((ac, el) => [...ac, ...el], []).find(o => o.id === i.orderId))}
                      <div className='download-item-id'>{i?.ref ? i.ref.replace('LD', '') : '-'}</div>
                      {moment(i?.time?.seconds * 1000).format("DD/MM/YYYY")}
                      {i?.orderId ?
                        <Link to={`/admin/order/${i.orderId}?type=rb`}>
                          <span>View</span>
                        </Link> :
                        <span />
                      }
                    </div>
                  ))}
                  {afs.length > 1 && (
                    <Pagination
                      totalPages={afs.length}
                      currentPage={afsCurrPage}
                      setPage={setAfsCurrPage}
                    />
                  )}
                </>
                :
                <p className='error' style={{ margin: 0 }}>{t('portal_customers_view_empty')}</p>
              }
            </Box>
          </Flex>

          <h3 className="title" style={{ fontSize: '26px' }}>{t('portal_customers_view_questionnaires_attachments_title')}</h3>

          <hr className='hr' />

          <Flex
            justifyContent="space-between"
            flexDirection={{ lg: 'row', sm: 'column' }}
            gap={{ lg: '40px', sm: '20px' }}
          >
            <Box flex={{ lg: '1', sm: 'none' }}>
              <h3 className='title' style={{ fontSize: '26px', color: '#3D2541', marginBottom: '20px' }}>{t('portal_customers_view_questionnaires_table_title')}</h3>
              {answers?.length > 0 ?
                <>
                  {answers[answersCurrPage].map(i => (
                    <div
                      key={i.id}
                      className="download-item download-item-user"
                    >
                      <Text display="inline-block" minWidth="20">
                        {i.mark === 'weekly' ? t('portal_answer_mark_1') : t('portal_answer_mark_2')}
                      </Text>
                      <div className='download-item-id'>{i?.ref ? i.ref.replace('LD', '') : '-'}</div>
                      {moment(i?.time).format("DD/MM/YYYY")}
                      <Link to={`/admin/answer/${id}/${i.id}`}><span>See more</span></Link>
                    </div>
                  ))}
                  {answers.length > 1 && (
                    <Pagination
                      totalPages={answers.length}
                      currentPage={answersCurrPage}
                      setPage={setAnswersCurrPage}
                    />
                  )}
                </>
                :
                <p className='error' style={{ margin: 0 }}>{t('portal_customers_view_empty')}</p>
              }
            </Box>
            <Box flex={{ lg: '1', sm: 'none' }}>
              <Flex 
                alignItems={{md: "center", base: 'flex-start'}}
                justifyContent="space-between" 
                mb="20px" 
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
                gap={{base: '10px', md: '40px'}}
              >
                <h3 className='title' style={{ fontSize: '26px', color: '#3D2541' }}>{t('portal_customers_view_attachments_table_title')}</h3>
                <label>
                  <input hidden type="file" multiple onChange={addAttachment} disabled={attachmentIsUploading} />
                  <span
                    className='custom-link'
                    style={{
                      color: attachmentIsUploading ? 'rgba(166, 166, 166, 0.6)' : '#92001A'
                    }}
                  >
                    {attachmentIsUploading
                      ? t('portal_customers_view_attachments_table_uploading')
                      : t('portal_customers_view_attachments_table_add')
                    }
                  </span>
                </label>
              </Flex>
              {attachments?.length > 0 ?
                <>
                  {attachments[attachmentsCurrPage].map(i => (
                    <div
                      key={i.id}
                      className="download-item download-item-user"
                      title={moment(i?.time).format("DD MMM HH:mm:ss")}
                    >
                      <span title={fileTypeCheck(i.name)}>{moment(i?.time).format("DD/MM/YYYY")}</span>
                      <Flex alignItems="center" gap="20px">
                        {editFile === i.id ? 
                          <Flex gap='20px' alignItems='center'>
                            <input type="text" value={editedFilename} onChange={(e) => setEditedFilename(e.target.value)} disabled={attachmentIsUploading} />
                            {!attachmentIsUploading && <Flex gap={3}>
                              <PlusCircle style={{ cursor: 'pointer' }} onClick={() => updateAttachment(editedFilename, i?.id)} />
                              <CloseIconSimple style={{ cursor: 'pointer', width: 10 }} onClick={() => setEditFile(null)}/>
                            </Flex>}
                          </Flex>
                          : 
                        (<>
                          <div className="products-table-title">
                            <a download={i.name} title={i.name} href={i.url} target="_blank">
                              <span>{i.name}</span>
                            </a>
                            {fileTypeCheck(i.name) === 'image' &&
                              <span style={{padding: '5px', maxWidth: 'unset'}}>
                                <img src={i.url} style={{minWidth: '200px'}} alt={'preview'} />
                              </span>
                            }
                          </div>
                          <EditIconSimple style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setEditFile(i.id)
                              setEditedFilename(i.name)
                            }}
                          />
                        </>
                        )}
                        <TrashIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => removeAttachment(i.name, i.id)}
                        />
                      </Flex>
                    </div>
                  ))}
                  {attachments.length > 1 && (
                    <Pagination
                      totalPages={attachments.length}
                      currentPage={attachmentsCurrPage}
                      setPage={setAttachmentsCurrPage}
                    />
                  )}
                </>
                :
                <p className='error' style={{ margin: 0 }}>{t('portal_customers_view_empty')}</p>
              }
            </Box>
          </Flex>

          <Flex
            justifyContent="space-between"
            flexDirection={{ lg: 'row', sm: 'column' }}
            gap={{ lg: '0', sm: '20px' }}
            mb="20px"
          >
            <div>
              <h3 className='title' style={{ fontSize: '26px', color: '#3D2541', margin: '20px 0' }}>{t('portal_customers_view_messages_title')}</h3>
              {mailgunStats?.length > 0 ?
                <>
                  {mailgunStats[mailgunStatsCurrPage].map(i => (
                    <div
                      key={i.id}
                      className="download-item"
                    >
                      <div className='download-item-id'>{i?.title}</div>
                      {moment(i?.created).format("DD/MM/YYYY")}
                    </div>
                  ))}
                  {mailgunStats.length > 1 && (
                    <Pagination
                      totalPages={mailgunStats.length}
                      currentPage={mailgunStatsCurrPage}
                      setPage={setMailgunStatsCurrPage}
                    />
                  )}
                </>
                :
                <p className='error' style={{ margin: 0 }}>{t('portal_customers_view_empty')}</p>
              }
            </div>
          </Flex>
        </div>
      }
    </Flex>
  )
}

export default ExpertsUserInfo