import React, { useEffect, useState } from "react";
import {
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";
import { useSelector } from "react-redux";
import { SearchIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPayments } from "helpers";
import api from "api";
import PaymentTable from "./PaymentTable";

const Payments = () => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const [payments, setPayments] = useState([]);
  const [historyData, setHistoryData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [discount, setDiscount] = useState(5)

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const onChange = useAsyncDebounce((value) => {
    setSearchValue(value || undefined);
  }, 200);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const { data: historyArr } = await api.get('paymentsByExpertID', { params: { expertId: user.id, status: 'complete' } })
      setHistoryData(historyArr || [])

      const storagePayments = JSON.parse(localStorage.getItem(`payments_${user.id}`))
      const storageDiscounts = JSON.parse(localStorage.getItem(`discounts_${user.id}`))

      if (!storagePayments) {
        const paymentsData = await getPayments(user.id)
        if (paymentsData) {
          const {
            payments: paymentsArr,
            discount,
            rbDiscount,
            qcADiscount,
            qcBDiscount,
            userCurrency,
          } = paymentsData
          setPayments(paymentsArr)
          setDiscount(discount)
        }
      } else {
        setPayments(storagePayments)
        setDiscount(storageDiscounts?.discount)
      }
    } catch (err) {
      console.log("err", err);
      setIsError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchData();
    }

    const storageListener = () => {
      const storagePayments = JSON.parse(localStorage.getItem(`payments_${user.id}`))
      const storageDiscounts = JSON.parse(localStorage.getItem(`discounts_${user.id}`))

      setPayments(storagePayments)
      setDiscount(storageDiscounts?.discount)
    }

    window.addEventListener('storage', storageListener)
    return () => window.removeEventListener('storage', storageListener)
  }, [user.id]);

  return (
    <Flex direction="column">
      <h2 className="title">{t('portal_payments_top_title')}</h2>
      <p className="descr">{t('portal_payments_top_description')}</p>

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && !payments.length && historyData.length ? (
        <Flex justifyContent="flex-end" mt="20px">
          <span className="custom-link">
            <Link to="/admin/payments/history"> {t('portal_payments_link')}</Link>
          </span>
        </Flex>
      ) : null}
      {!isLoading && isError && <p className="error">{isError}</p>}
      {!isLoading && !isError ? (
        payments?.length ? (
          <>
            <Flex
              alignItems={{ md: "center", base: 'flex-start' }}
              justifyContent="space-between"
              flexDirection={{
                base: "column",
                md: "row",
              }}
              mb={{ md: 0, base: '20px' }}
            >
              <label className="custom-input-wrapper">
                <SearchIcon color={'#3D2541'} w="18px" h="18px" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    onChange(e.target.value);
                  }}
                  placeholder={t('portal_payments_table_search_placeholder')}
                  className={'custom-input'}
                />
              </label>
              {historyData.length ? (
                <span className="custom-link">
                  <Link to="/admin/payments/history"> {t('portal_payments_link')}</Link>
                </span>
              ) : null}
            </Flex>

            <h3 className="title payments-title">GBP Payments</h3>
            <PaymentTable
              currency={'gbp'}
              initPayments={payments}
              search={searchValue}
              discount={discount}
            />
            <br />
            <hr className="hr" />

            <h3 className="title payments-title">Euro Payments</h3>
            <PaymentTable
              currency={'eur'}
              initPayments={payments}
              search={searchValue}
              discount={discount}
            />
            <br />
          </>
        ) : (
          <p className="error">{t('portal_payments_empty_table')}</p>
        )
      ) : null}
    </Flex>
  );
};

export default Payments;
