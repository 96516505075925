import {
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Text
} from '@chakra-ui/react'
import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg'
import classNames from 'classnames'
import Checkbox from 'components/Checkbox'
import IndeterminateCheckbox from 'components/IndeterminateCheckbox'
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'
import { getTierLevel } from 'helpers'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlexLayout, useRowSelect, useTable } from 'react-table'
import { db } from '../../../firebase'

const percents = [0, 2.5, 5]

const checkCurrency = (curr) => {
  if(curr === 'HU') return 'EUR'
  if(curr === 'UK') return 'GBP'
  return 'EUR'
}

const ProductDropdown = ({prices, rbDiscount, setRbDiscount, currency}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const clickHandler = (percent) => {
    setIsOpen(false)
    setRbDiscount(percent)
  }

  return (
    <div className='experts-products-table'>
      <div className="experts-products-table-head">
        {t('portal_experts_view_table_head_1')}
      </div>
      <div className="experts-products-table-row">
        <div>{prices[`rbPricePartner${rbDiscount}${currency}`] / 100} {checkCurrency(currency)}</div>
        <div>{rbDiscount}%</div>
        <div 
          className={classNames("experts-products-table-icon", {'active': isOpen})}
          onClick={() => setIsOpen(prev => !prev)}
        >
          <ArrowDown />
        </div>
      </div>

      <div className={classNames("experts-products-table-accordion", {'active': isOpen})}>
        {percents
          .filter(i => i !== rbDiscount)
          .map(j => (
            <div 
              key={j}
              className="experts-products-table-row"
              onClick={() => clickHandler(j)}
            >
              <div>{prices[`rbPricePartner${j}${currency}`] / 100} {checkCurrency(currency)}</div>
              <div>{j}%</div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

const ExpertProducts = (props) => {
  const {
    expertId,
    rbAvailability,
    setRbAvailability,
    qcAvailability,
    setQcAvailability,
    ipAvailability,
    setIpAvailability,
    tierDiscount,
    setTierDiscount,
    rbDiscount,
    setRbDiscount,
    individualProducts,
    setIndividualProducts,
    currency
  } = props

  const { t } = useTranslation()

  const [products, setProducts] = useState([])
  const [prices, setPrices] = useState({})
  const [limit, setLimit] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const columns = useMemo(() => [
    {
      Header: t('portal_experts_view_table_head_2'),
      accessor: "title",
    },
    {
      Header: () => <span className='individual-products-table-td-accent'>{t('portal_experts_view_table_head_1')}</span>,
      accessor: "price",
      Cell: ({ value }) =>
        <Text fontWeight={500} className='individual-products-table-td-accent'>
          {value ? value / 100 + ' ' + checkCurrency(currency) : '-'}
        </Text>,
      width: 240,
      minWidth: 240,
      maxWidth: 1,
    },
  ], [ipAvailability]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data: products,
      initialState: {
        selectedRowIds: products.reduce((prev, curr, index) => ({
          ...prev,
          [index]: index === 0 ? true : individualProducts.includes(curr.ref)
        }), {})
      },
    },
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                disabled={!ipAvailability}
              />
            </div>
          ),
          Cell: ({ row }) => (
            row.index === 0 ?
              <Checkbox 
                checked 
                onChange={() => {}} 
                disabled={!ipAvailability}
              /> :
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  disabled={!ipAvailability}
                />
              </div>
          ),
          width: 78,
          minWidth: 78,
          maxWidth: 1,
        },
        ...columns
      ]);
    }
  );

  const resetDiscountHandler = async () => {
    // await api.post('updateUser', { id: expertId, "tierDiscount": deleteField() })

    // await updateDoc(doc(db, 'Users', expertId), {
    //   "tierDiscount": deleteField()
    // })
    setTierDiscount(0)
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)

      let productsData = []
      const productsRef = collection(db, 'Products')
      const productsSnap = await getDocs(productsRef)
      if (!productsSnap.empty) {
        productsSnap.forEach(doc => {
          productsData.push({ id: doc.id, ...doc.data() })
        })
      }

      const pricesRef = doc(db, 'Meta', 'PartnersPrices')
      const pricesDoc = await getDoc(pricesRef)
      if (pricesDoc.exists()) {
        const pricesData = pricesDoc.data()
        setPrices(pricesData)
        productsData = productsData
          .map(i => ({
            ...i,
            price: pricesData[`i${i.ref}PricePartner0${currency}`]
          }))
          .filter(j => j.ref !== 'p22' && j.ref !== 'p795')
          .sort((a, b) => Number(a.ref?.replace(/\D/g, '')) - Number(b.ref?.replace(/\D/g, '')))

        productsData.unshift({
          title: t('portal_experts_view_shipping_fee'),
          price: pricesData[`ipShippingPricePartner0${currency}`]
        })
      }

      setProducts(productsData)

      const tierLevel = await getTierLevel(expertId)
      setLimit(tierLevel)
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if(!isLoading && !isError) {
      const refs = selectedFlatRows
        .map(i => i?.original?.ref)
        .filter(j => j)
  
      setIndividualProducts(refs)
    }
  }, [selectedFlatRows])

  return (
    <div>
      {isLoading && (
        <Flex justifyContent="center" mb="30px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && <p className="error">{t('portal_error_message')}</p>}
      {!isLoading && !isError ? <>
        <Flex
          gap="38px"
          mb="43px"
          flexDirection={{ lg: 'row', sm: 'column' }}
        >
          <Box flex="1 1 0">
            <h3 className='title'>{t('portal_experts_view_title_1')}</h3>
            <Box mt="30px">
              <label className='experts-products-label'>
                {t('portal_experts_view_product_1')}
                <Checkbox
                  checked={rbAvailability}
                  onChange={ev => setRbAvailability(ev.target.checked)}
                />
              </label>

              <ProductDropdown 
                prices={prices} 
                rbDiscount={rbDiscount}
                setRbDiscount={setRbDiscount}
                currency={currency}
              />
            </Box>

            <Box mt="43px">
              <label className='experts-products-label'>
                {t('portal_experts_view_product_2')}
                <Checkbox
                  checked={qcAvailability}
                  onChange={ev => setQcAvailability(ev.target.checked)}
                />
              </label>

              <div className='experts-products-table'>
                <div className="experts-products-table-head">
                  {t('portal_experts_view_table_head_1')}
                </div>
                <div className="experts-products-table-row">
                  <div>{prices[`dsPricePartner${rbDiscount}${currency}`] / 100} {checkCurrency(currency)}</div>
                  <div>{rbDiscount}%</div>
                </div>
                <div className="experts-products-table-row">
                  <div>{prices[`dsPricePartner${rbDiscount}${currency}`] / 100} {checkCurrency(currency)}</div>
                  <div>{rbDiscount}%</div>
                </div>
              </div>
            </Box>
          </Box>
          <Box flex="1 1 0">
            <h3 className='title'>{t('portal_experts_view_title_2')}</h3>
            <Flex alignItems="center" gap="14px" mt="26px">
              <Text
                fontWeight={600}
                fontSize={"15px"}
                lineHeight={"135%"}
                color={"#3D2541"}
              >{t('portal_experts_view_tier')}:</Text>
              <div className='af-tabs experts-tabs'>
                <div className={`af-tab ${limit === 5 && 'af-tab-active'}`}>
                  5%
                </div>
                <div className={`af-tab ${limit === 10 && 'af-tab-active'}`}>
                  10%
                </div>
                <div className={`af-tab ${limit === 15 && 'af-tab-active'}`}>
                  15%
                </div>
                <div className={`af-tab ${limit === 20 && 'af-tab-active'}`}>
                  20%
                </div>
              </div>
            </Flex>

            <Text
              fontSize={"15px"}
              fontWeight={600}
              mt="38px"
            >{t('portal_experts_view_commission')}</Text>
            <Flex
              alignItems={"center"}
              mt="7px"
              gap={"20px"}
            >
              <NumberInput
                flex={"1 1 0"}
                size='md'
                min={0}
                max={100}
                step={0.1}
                borderColor={'rgba(61, 37, 65, 0.4)'}
                value={tierDiscount || 0}
                onChange={(str, number) => setTierDiscount(number)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <button
                type='button'
                className="custom-link"
                onClick={resetDiscountHandler}
              >{t('portal_experts_view_reset_btn')}</button>
            </Flex>
          </Box>
        </Flex>

        <Box mt="30px">
          <label className='experts-products-label'>
            {t('portal_experts_view_ip')}
            <Checkbox
              checked={ipAvailability}
              onChange={ev => setIpAvailability(ev.target.checked)}
            />
          </label>
        </Box>

        <div className="individual-products-table-scroll">
          <div
            {...getTableProps()}
            className="individual-products-table"
          >
            <div>
              {headerGroups.map(headerGroup => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="individual-products-table-tr"
                >
                  {headerGroup.headers.map(column => (
                    <div
                      {...column.getHeaderProps()}
                      className="individual-products-table-th"
                    >
                      {column.render('Header')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div
              {...getTableBodyProps()}
              className="individual-products-table-body"
            >
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <div
                    {...row.getRowProps()}
                    className="individual-products-table-tr"
                  >
                    {row.cells.map(cell => (
                      <div
                        {...cell.getCellProps()}
                        className="individual-products-table-td"
                      >
                        {cell.render('Cell')}
                      </div>
                    ))}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </> : null}
    </div>
  )
}

export default ExpertProducts