import { Badge, Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Stack, Text, Textarea, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import api from 'api'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PackagePdfModal } from './PackagePdf'

const getProductType = (type) => {
  switch (type) {
    case 'rb':
      return 'Red Box'
    case 'ds':
      return 'Discovery Set'
    case 'se':
      return 'Serum'
    case 'st':
      return 'Set'
    case 'ip':
      return 'Individual Product'
    default:
      return 'Unknown'
  }
}

const getTitleByRefProduct = (ref, dict) => {
  const title = dict.find(i => i.ref === ref)?.title
  if (!title) {
    return ref
  }

  return title
}

const boxSizeTypes = [
  {
    value: 'Individual Box',
    label: 'Individual Box'
  },
  {
    value: 'Discovery Set Box',
    label: 'Discovery Set Box'
  },
  {
    value: 'Collection box',
    label: 'Collection box'
  },
  {
    value: 'Red Box',
    label: 'Red Box'
  }
]

const SendToProductionModal = (props) => {
  const {
    data,
    isOpen,
    onClose
  } = props

  const { t } = useTranslation()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  const [validError, setValidError] = useState(false)
  const [packageData, setPackage] = useState(null)
  const [order, setOrder] = useState(null)
  const [sendToProductionData, setSendToProductionData] = useState(null)

  const { isOpen: isTreatmentOpen, onOpen: onTreatmentOpen, onClose: onTreatmentClose } = useDisclosure()

  const { register, handleSubmit, formState: { errors }, watch, setValue, reset } = useForm()

  const products = watch('products')
  const files = watch('file_')
  
  const onSubmit = handleSubmit((data) => {
    const formData = new FormData()
    data?.file_?.length > 0 && formData.append('file', data?.file_[0])
    formData.append('orderId', data?.orderId)
    formData.append('note', data?.note)
    data?.boxSize && formData.append('boxSize', data?.boxSize)
    formData.append('expertId', packageData?.data?.package?.expertId)
    formData.append('initialId', data?.initialId || '')
    sendToProduction(formData)
  })

  const validateFiles = (value) => {
    for (const file of Array.from(value || [])) {
      const fsMb = file.size / (1024 * 1024)
      const MAX_FILE_SIZE = 10
      if (fsMb > MAX_FILE_SIZE) {
        // setIsError('Max file size 10mb')
        return false
      }
    }
    return true
  }

  const getPackage = async (packageId) => {
    try {
      await api.get(`packageByID?packageId=${packageId}&withTreatments=1`).then(async res => {

      setPackage(res)
      })
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const getSendToProd = async () => {
    try {
      await api.get(`sendToProduction?orderId=${data?.orderId}`).then(async res => {
        setSendToProductionData(res)
      })
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } 
  }

  const sendToProduction = async (data) => {
    try {
      setIsSubmitting(true)
      await api.post('sendToProduction', data).then(async res => {
        if (res?.data?.success) {
          toast({
            title: res?.data?.message,
            position: 'top-right',
            isClosable: true,
            status: 'success'
          }) 
          reset()
          onClose()
        } else {
          toast({
            title: res?.data?.message,
            position: 'top-right',
            isClosable: true,
            status: 'error'
          })
          reset()
          onClose() 
        }
      })
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const getOrder = useCallback(async () => {
    setIsLoading(true)
    try {
      if (data?.orderId) {
        const {data: orderData} = await api.get('orderByID', { params: { 
          orderId: data?.orderId } })
        setOrder(orderData)
      }
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [data?.orderId])

  useEffect(() => {
    const getData = async () => {
      await getOrder()
      await getPackage(order?.packageId)
      await getSendToProd()
    }

    getData()
  }, [order?.packageId, getOrder])

  useEffect(() => {
    if (packageData?.data?.package?.id) {
      if (packageData?.data?.package?.type === 'rb') {
      setValue(
        'products', 
        packageData?.data?.package?.products?.map(i => ({ title: i.title, qty: i.qty }))
      )
      }
      if (['ds', 'ip', 'se', 'st'].includes(packageData?.data?.package?.type)) {
        const products = packageData?.data?.package?.products.map(i => ({ title: getTitleByRefProduct(i.ref, packageData?.data?.productsDict), qty: i.quantity }))
        setValue('products', products)
      }
      setValue('orderId', data?.orderId)
    }
  }, [packageData, setValue])

  useEffect(() => {
    if (sendToProductionData?.data?.initial_message) {
      setValue('note', sendToProductionData?.data?.initial_message?.note)
      setValue('boxSize', sendToProductionData?.data?.initial_message?.boxSize)
      setValue('initialId', sendToProductionData?.data?.initial_message?.id)
    }
    if (sendToProductionData?.data?.updates?.length > 0) {
      const lastUpdate = sendToProductionData?.data?.updates[sendToProductionData?.data?.updates?.length - 1]
      setValue('note', lastUpdate.note)
      setValue('boxSize', lastUpdate.boxSize)
    }
  }, [sendToProductionData, setValue])

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={() => {
        setValidError(false)
        setIsError(false)
        onClose()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>{t('portal_orders_send_to_prod_modal_title')}</ModalHeader>
          <ModalCloseButton /> 
          <ModalBody>
            {isLoading && (
              <Flex mx={"auto"} justifyContent='center' alignItems='center'>
                <Spinner color="red.700" size="xl" />
              </Flex>
            )}
            {!isLoading && isError && (
              <p className="error" style={{ margin: '0 auto' }}>{t('portal_error_message')}</p>
              )}
            {!isLoading && !isError ? <>
                <Stack spacing={5} direction='column'>
                  <Stack spacing={5} direction='row' wrap='wrap'>
                    <Text fontWeight={700} fontSize='md' color='#92001a'>{t('portal_orders_send_to_prod_modal_full_name')}:</Text> 
                    <Text fontWeight={500} >{
                      `${packageData?.data?.userData?.firstName} ${packageData?.data?.userData?.lastName}`
                    }</Text>
                  </Stack>
                  <Stack spacing={5} direction='row' wrap='wrap'>
                    <Text fontWeight={700} fontSize='md' color='#92001a'>{t('portal_orders_send_to_prod_modal_order_type')}:</Text> 
                    <Text fontWeight={500} >{
                      getProductType(packageData?.data?.package?.type)
                    }</Text>
                  </Stack>
                  {packageData?.data?.package?.type === 'ip' && 
                  <FormControl isInvalid={!!errors.boxSize}>
                    <Stack spacing={5} direction='row' alignItems='center'>
                      <FormLabel m={0} flexShrink={1}>
                        <Text fontWeight={700} fontSize='md' whiteSpace='nowrap' color='#92001a'>Box size:</Text>
                      </FormLabel>
                      <Select
                        h="34px"
                        _focus={{
                          boxShadow: 'none'
                        }}
                        colorScheme='red'
                        borderColor={'#3D2541'}
                        {...register('boxSize')}
                      >
                        { boxSizeTypes.map(p => (
                            <option value={p.value} key={p.value}>{p.label}</option>
                          ))
                        }
                      </Select>
                    </Stack>
                    <FormErrorMessage>
                        {errors.boxSize && errors?.boxSize.message}
                    </FormErrorMessage>
                  </FormControl>}
                  <Stack spacing={1} direction='column' wrap='wrap'>
                    <Text fontWeight={700} fontSize='md' color='#92001a'>{t('portal_orders_send_to_prod_modal_list_of_products')}:</Text> 
                    <Grid templateColumns='repeat(2, 1fr)' gap={4} rowGap={2}>
                      {products?.map((product, index) => (
                      <GridItem 
                        w='100%' 
                        maxW={195} 
                        h='10' 
                        borderColor='#92001a' 
                        borderWidth={1} p={2} 
                        borderRadius={6} 
                        key={index}
                        display='flex'
                        gap={1}
                      >
                        <Tooltip label={product.title}>
                          <Text fontWeight={500} fontSize='sm' textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>{product.title}</Text>
                        </Tooltip>
                        <Badge variant='subtle' colorScheme='red' borderRadius='50%' p={2} display='flex' alignItems='center' justifyContent='center'>
                          {product.qty}
                        </Badge>
                      </GridItem>
                      ))}
                    </Grid>
                  </Stack>
                  {packageData?.data?.package?.type === 'rb' && <Stack spacing={5} direction='row' wrap='wrap' alignItems='center'>
                    <Text fontWeight={700} fontSize='md' color='#92001a'>{t('portal_orders_send_to_prod_modal_treatment_plan')}:</Text> 
                    <Stack spacing={3} direction='row'>
                      <Button backgroundColor='#92001a' color='#fff' size='md'  _hover={{backgroundColor: '#750117'}} _active={{backgroundColor: '#750117'}} borderRadius='6px'
                      onClick={()=>{
                        onTreatmentOpen()
                      }}>
                          {t('portal_orders_send_to_prod_modal_generate_treatment_button')}
                      </Button>
                      <FormControl isInvalid={!!errors.file_} isRequired display='inline-block' width='auto'>
                        <FileUpload
                          accept={'application/pdf,application/msword'}
                          register={register('file_', { validate: validateFiles  })}
                        >
                          <Button backgroundColor='#92001a' _hover={{backgroundColor: '#750117'}} _active={{backgroundColor: '#750117'}}  color='#fff' size='md' borderRadius='6px'>
                            {t('portal_orders_send_to_prod_modal_upload_treatment_button')}
                          </Button>
                        </FileUpload>
                        <FormErrorMessage>
                          {errors.file_ && errors?.file_.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Stack>
                  </Stack>}
                  {files?.length > 0 && <Text fontSize='sm'>
                      <span style={{fontWeight: 700}}>Uploaded file:</span> { files[0]?.name}
                    </Text>} 
                  <FormControl isInvalid={!!errors.note}>
                    <Stack spacing={2} direction='column'>
                    <FormLabel m={0}>
                      <Text fontWeight={700} fontSize='md' color='#92001a'>{t('portal_orders_send_to_prod_modal_note_title')}:</Text>
                    </FormLabel>
                      <Textarea
                        {...register('note')}
                        type='text'
                        borderColor={'rgba(61, 37, 65, 0.4)'}
                        placeholder={t('portal_orders_send_to_prod_modal_note_placeholder')}
                        resize='none'
                        colorScheme='red'
                        focusBorderColor='red.500'
                      />
                    </Stack>
                    <FormErrorMessage>
                        {errors.note && errors?.note.message}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>

              {validError && (
                <p 
                  className="error" 
                  style={{ margin: '20px auto 0', opacity: 1, color: 'red' }}
                >
                  {t('portal_orders_new_error_1')}
                </p>
              )}
            </> : null}
            {isTreatmentOpen && (<PackagePdfModal packageData={packageData} 
            isOpen={isTreatmentOpen} onClose={()=>onTreatmentClose()} />)}
            {/* {showPdf && (<PackagePdf packageId={order.packageId} />)} */}
          </ModalBody>

          <ModalFooter justifyContent={"center"} >
            
              <button
                type="submit"
                className="custom-btn"
                disabled={isSubmitting ||isError}
              >
                <Tooltip label={files?.length < 1 ? 'Please upload treatment plan' : ''}>
                  <p>
                  {sendToProductionData?.data?.action === 'initial' && t('portal_orders_send_to_prod_modal_button')}
                  {sendToProductionData?.data?.action === 'update' && 'Update to Production'}
                  </p>
                </Tooltip>
              </button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default SendToProductionModal