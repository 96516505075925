import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    answerId: '',
};

const assessmentSlice = createSlice({
    name: "assessment",
    initialState,
    reducers: {
        setAnswerId(state, action) {
            state.answerId = action.payload;
        }           
    },
});

export const { setAnswerId } = assessmentSlice.actions;

export default assessmentSlice.reducer;
