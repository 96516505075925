import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import assessmentReducer from "./slices/assessmentSlice";
import notificationSlice from "./slices/notificationSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        assessment: assessmentReducer,
        notification: notificationSlice,
    },
    devTools: process.env.REACT_APP_ENV !== 'production',
});
