import React, { useEffect, useMemo, useState } from 'react'
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useAsyncDebounce, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import csvDownload from 'json-to-csv-export'
import Pagination from 'components/Pagination';
import { Link } from 'react-router-dom';
import api from 'api';

const Commissions = () => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currency, setCurrency] = useState('gbp')
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')

  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    return data.filter(i => i?.currency && i.currency === currency)
  }, [data, currency])

  const columns = useMemo(() => [
    {
      Header: t('portal_payment_history_table_column_4_title'),
      accessor: 'ref',
      Cell: (row) => row.row.original?.ref ? <Link to={`/admin/payments/${row.row.original?.id}`}><u>{row.row.original?.ref?.replace('LD', '')}</u></Link> : '-',
      width: 110,
      maxWidth: 110,
      minWidth: 110,
    },
    {
      Header: t('portal_commissions_table_column_1_title'),
      accessor: "time",
      Cell: ({ value }) => value
        ? <span title={moment(value).format("HH:mm MMM DD YYYY")}>
          {moment(value).format("DD/MM/YYYY")}
        </span>
        : "-",
      width: 90,
      maxWidth: 90,
      minWidth: 90,
      sortType: (a, b) => {
        if (new Date(a.values.time).getTime() < new Date(b.values.time).getTime()) {
          return -1;
        } else if (new Date(a.values.time).getTime() > b.values.time) {
          return 1;
        };

        return 0;
      },

    },
    {
      Header: t('portal_commissions_table_column_2_title'),
      accessor: "consumerTotal",
      Cell: (row) => row.row.original?.consumerTotal
        ? <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original.consumerTotal / 100).toFixed(2)}</b>
        : '-',
      width: 80,
      maxWidth: 80,
      minWidth: 80,
    },
    {
      Header: () => <div className='products-table-title'>
        {t('portal_commissions_table_column_3_title')}
        <span>{t('portal_commissions_table_tooltip_1')}</span>
      </div>,
      accessor: "acr",
      Cell: ({ value }) => value ? `${value}%` : '-',
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: t('portal_commissions_table_column_4_title'),
      accessor: "commissions",
      Cell: (row) => <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original?.commissions / 100).toFixed(2)}</b>,
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
    {
      Header: () => <div className='products-table-title'>
        {t('portal_commissions_table_column_5_title')}
        <span>{t('portal_commissions_table_tooltip_2')}</span>
      </div>,
      accessor: "cpo",
      Cell: (row) => row.row.original?.cpo
        ? <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original.cpo / 100).toFixed(2)}</b>
        : '-',
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: t('portal_commissions_table_column_6_title'),
      accessor: "cd",
      Cell: (row) => row.row.original?.cd
        ? <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original.cd / 100).toFixed(2)}</b>
        : '-',
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
  ], []);

  const dataToConvert = useMemo(() => ({
    data: data.map(i => ({
      time: i?.time ? moment(i?.time).format("DD/MM/YYYY") : '-',
      total: (i?.total / 100).toFixed(2),
      acr: i?.acr ? `${i?.acr}%` : '-',
      commissions: (i?.commissions / 100).toFixed(2),
      cpo: i?.cpo ? `£${(i.cpo / 100).toFixed(2)}` : '-',
      cd: i?.cd ? `£${(i.cd / 100).toFixed(2)}` : '-'
    })),
    filename: 'commissions_report',
    delimiter: ',',
    headers: [
      t('portal_commissions_table_column_1_title'),
      t('portal_commissions_table_column_2_title'),
      t('portal_commissions_table_column_3_title'),
      t('portal_commissions_table_column_4_title'),
      t('portal_commissions_table_column_5_title'),
      t('portal_commissions_table_column_6_title'),
    ]
  }), [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     ...columns,
    //     {
    //       id: "action",
    //       Header: () => 'Manage',
    //       Cell: ({ row }) => (
    //         <TableDropdown>
    //           <div
    //             className="dropdown-item"
    //             onClick={() =>
    //               push(`/admin/commissions/${row.original.id}`)
    //             }
    //           >
    //             <Doc /> {t('portal_commissions_table_menu_1')}
    //           </div>
    //         </TableDropdown>
    //       )
    //     }
    //   ]);
    // }
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const fetchData = async (dateFrom, dateTo) => {
    try {
      setIsLoading(true)

      let {data: paymentsData} = await api.get('paymentsByExpertID', {params: {expertId: user.id, status: 'complete'}})

      paymentsData = paymentsData?.filter(i => 
        moment(i?.time)?.isSameOrAfter(new Date(dateFrom ? dateFrom : null)) && 
        moment(i?.time)?.isSameOrBefore(dateTo ? new Date(dateTo) : new Date())
      ) || []

      const data = paymentsData.map(i => {
        const commission = i.consumerTotal - i.total

        return {
          ...i,
          consumerTotal: i.payment_type !== 'paid_out' ? i.consumerTotal : 0,
          commissions: i.payment_type !== 'paid_out' ? commission : i.total,
          cpo: i.payment_type !== 'paid_out' ? i.payment_type === 'stripe' ? commission : 0 : i.total,
          cd: (i.payment_type === 'shopify' || i.payment_type === 'customer') ? commission : 0,
        }
      })
      setData(data.sort((a, b) => a.time?.seconds < b.time?.seconds ? 1 : -1))
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (user.id) {
      fetchData(dateFrom, dateTo);
    }
  }, [user.id, dateFrom, dateTo]);

  return (
    <Flex direction="column">
      <h2 className='title'>{t('portal_commissions_top_title')}</h2>
      <p className='descr'>{t('portal_commissions_top_description')}</p>

      {isLoading && <Flex mx={"auto"} mt="20px"><Spinner color="red.700" size="xl" /></Flex>}
      {!isLoading && isError && <p className='error'>{t('portal_error_message')}</p>}
      {!isLoading && !isError ? (
        <>
          <Flex
            alignItems={{ md: "center", base: 'flex-start' }}
            justifyContent="space-between"
            flexDirection={{
              base: "column",
              md: "row",
            }}
            mb={{ md: 0, base: '20px' }}
          >
            <label className="custom-input-wrapper">
              <SearchIcon color={'#3D2541'} w="18px" h="18px" />
              <input
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  onChange(e.target.value);
                }}
                placeholder={t('portal_commissions_table_search_placeholder')}
                className={'custom-input'}
              />
            </label>
            <div
              className='custom-link'
              onClick={() => csvDownload(dataToConvert)}
            >
              {t('portal_commissions_download_button')}
            </div>
          </Flex>

          <Flex
            alignItems={{ md: "center", base: 'flex-start' }}
            justifyContent="space-between"
            flexDirection={{
              base: "column",
              md: "row",
            }}
            mb={'20px'}
            gap={'20px'}
          >
            <Flex gap="38px" flexDirection={{ lg: 'row', sm: 'column' }}>
              <Box textAlign={'center'}>
                <div className='af-tabs' style={{margin: 0}}>
                  <div
                    className={`title af-tab ${currency === 'gbp' && 'af-tab-active'}`}
                    onClick={() => setCurrency('gbp')}
                  >
                    GBP
                  </div>
                  <div
                    className={`title af-tab ${currency === 'eur' && 'af-tab-active'}`}
                    onClick={() => setCurrency('eur')}
                  >
                    EUR
                  </div>
                </div>
              </Box>
            </Flex>

            <Flex
              alignItems={"center"}
              justifyContent="space-between"
              flexDirection={{
                base: "column",
                md: "row",
              }}
              gap={'8px'}
            >
              <input 
                className='custom-input' 
                style={{padding: '5px 10px'}} 
                type="date" 
                value={dateFrom} 
                onChange={(ev) => setDateFrom(ev.target.value)}
              />
              <span>-</span>
              <input 
                className='custom-input' 
                style={{padding: '5px 10px'}} 
                type="date" 
                value={dateTo} 
                onChange={(ev) => setDateTo(ev.target.value)}
              />
            </Flex>
          </Flex>

          {data?.length ? (<>
            <div
              className='table'
              {...getTableProps()}
            >
              <div className='thead'>
                {headerGroups.map((headerGroup) => (
                  <div className='tr' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <div
                        className='th'
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className='table-sort'>
                          {column.canSort ? (
                            <>
                              {column?.isSortedDesc && <> &#9660;</>}
                              {column?.isSortedDesc === false && <> &#9650;</>}
                              {column?.isSortedDesc === undefined && (
                                <span className='sort-arrows'>
                                  <span>&#9650;</span>
                                  <span>&#9660;</span>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className='tbody' {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <div
                      key={row.original.id}
                      className='tr'
                      {...row.getRowProps()}
                      style={{
                        ...row.getRowProps().style,
                        background: row.original.payment_type === "paid_out" ? '#bfefbf' : ''
                      }}
                    >
                      {row.cells.map((cell) => (
                        <div className='td' {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <Pagination
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
            />
          </>) : (
            <p className='error'>{t('portal_commissions_empty_table')}</p>
          )}
        </>
      ) : null}
    </Flex>
  )
}

export default Commissions