import { Flex, Spinner } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'store/slices/userSlice'
import { setNotificationCount, makeNextCall } from "store/slices/notificationSlice";
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Select } from "chakra-react-select";
import api from 'api'

const SwitchUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { push } = useHistory()

  const { id, originalId, role_id, canEditOrder } = useSelector(state => state.user);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const switchHandler = () => {
    const newUserData = users.find(i => i.id === selectedUser.value)

    const newUser = {
      email: newUserData.email,
      id: newUserData.id,
      originalId: originalId,
      avatar: newUserData.picture,
      name: 
        ((newUserData?.firstName && newUserData?.lastName) && `${newUserData?.firstName} ${newUserData?.lastName}`) ||
        newUserData?.name,
      role_id: role_id,
      canEditOrder: canEditOrder
    }
    dispatch(setUser(newUser));
    dispatch(setNotificationCount(0));
    dispatch(makeNextCall());
    localStorage.setItem('switched_user', JSON.stringify(newUser));
    push('/admin/dashboard');
  }

  const resetHandler = async () => {
    const { data: userData } = await api.get('userByID', {params: {userId: originalId}})

    const newUser = {
      email: userData.email,
      id: originalId,
      originalId: originalId,
      avatar: userData.picture,
      name: userData.firstName + ' ' + userData.lastName,
      role_id: role_id,
      canEditOrder: canEditOrder
    }
    dispatch(setUser(newUser))
    localStorage.removeItem('switched_user');
    dispatch(setNotificationCount(0));
    dispatch(makeNextCall());
    push('/admin');
  }

  const fetchUsers = async () => {
    try {
      setIsLoading(true)

      const {data: experts} = await api.get('expertsList')
      const {data: admins} = await api.get('getAdmins')

      const usersArr = [...experts, ...admins]

      if (usersArr.length) {
        setUsers(usersArr)

        const optionsArr = usersArr.map(i => ({
          value: i.id,
          label: `<${i.email}> ${
            ((i?.firstName && i?.lastName) && `${i?.firstName} ${i?.lastName}`) ||
            i?.name || '-'}
          `
        }))

        setOptions(optionsArr)

        if (id !== originalId) {
          setSelectedUser(optionsArr.find(i => i.value === id))
        }
      }

    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if(role_id != 2) {
      push('/admin')
      return
    }
    fetchUsers()
  }, [])

  return (
    <Flex direction="column">
      <h2 className="title">Switch user</h2>

      {isLoading && <Flex mx={"auto"} mt="20px"><Spinner color="red.700" size="xl" /></Flex>}
      {!isLoading && isError && <p className='error'>{t('portal_error_message')}</p>}

      {!isLoading && !isError ? (
        users?.length ? <>
          <Flex mt="20px" alignItems={"center"} gap="20px">
            <Select
              value={selectedUser}
              onChange={(value) => setSelectedUser(value)}
              options={options}
              placeholder="Select user"
              chakraStyles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                  height: '100%',
                  borderColor: 'rgba(61, 37, 65, 0.4)'
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  bg: "transparent",
                  px: 2,
                  cursor: "inherit"
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none"
                })
              }}
            />
            <button
              type="button"
              className="custom-btn"
              onClick={switchHandler}
            >Switch</button>
          </Flex>

          <Flex mt="20px" alignItems={"center"} gap="20px" justifyContent={"flex-end"}>
            <button
              type="button"
              className="custom-btn"
              onClick={resetHandler}
            >Reset</button>
          </Flex>
        </> : null
      ) : null}
    </Flex>
  )
}

export default SwitchUser