import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { SearchIcon } from '@chakra-ui/icons'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import { BackArrow } from 'components/Icons/Icons'
import moment from 'moment'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAsyncDebounce, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/Pagination'
import { useSelector } from 'react-redux'
import api from 'api'

const PaymentDetails = () => {
  const { t } = useTranslation();
  const history = useHistory()

  const {paymentId} = useParams()

  const user = useSelector(state => state.user)

  const [payment, setPayment] = useState({});
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("");

  const columns = useMemo(() => [
    {
      Header: t('portal_payment_detail_table_column_1_title'),
      accessor: 'ref',
      Cell: (row) => row.row.original?.ref 
        ? <Link to={
            `/admin/order/${row.row.original.id}${(row.row.original.type.includes('qc'))
              ? '?type=qc'
              : row.row.original.type === 'ds'
                ? '?type=ds'
                : row.row.original.type === 'se'
                  ? '?type=se'
                  : row.row.original.type === 'st'
                    ? '?type=st'
                    :row.row.original.type === 'ip'
                      ? '?type=ip'
                      : '?type=rb&tab=products'
            }`
          }>
            <u>{row.row.original?.ref?.replace('LD', '')}</u>
          </Link> 
        : '-',
      width: 110,
      maxWidth: 110,
      minWidth: 110,
    },
    {
      Header: t('portal_payment_detail_table_column_2_title'),
      accessor: "created",
      Cell: ({ value }) => value 
        ? <span title={moment(value).format("HH:mm MMM DD YYYY")}>
            {moment(value).format("DD/MM/YYYY")}
          </span> 
        : "-",
      width: 90,
      maxWidth: 90,
      minWidth: 90,
      sortType: (a, b) => {
        if (new Date(a.values.created).getTime() < new Date(b.values.created).getTime()) {
          return -1;
        } else if (new Date(a.values.created).getTime() > new Date(b.values.created).getTime()) {
          return 1;
        };
  
        return 0;
      },
    },
    {
      Header: t('portal_payment_detail_table_column_3_title'),
      accessor: "userName",
      Cell: (value) => {
        return <Link to={`/admin/customers/${value.cell.row.original.user_id}`}>
          <u>{value.cell.row.original.userName}</u>
        </Link>
      }
    },
    {
      Header: t('portal_payment_detail_table_column_4_title'),
      accessor: "type",
      Cell: ({value}) => value ? value.toUpperCase() : '-',
      width: 70,
      maxWidth: 70,
      minWidth: 70,
    },
    {
      Header: t('portal_payment_detail_table_column_5_title'),
      accessor: "consumerTotal",
      Cell: (row) => row.row.original?.consumerTotal
        ? <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original?.consumerTotal / 100).toFixed(2)}</b>
        : '-',
      width: 110,
      maxWidth: 110,
      minWidth: 110,
    },
    {
      Header: t('portal_payment_detail_table_column_6_title'),
      accessor: "total",
      Cell: (row) => row.row.original?.total
        ? <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original?.total / 100).toFixed(2)}</b>
        : '-',
      width: 90,
      maxWidth: 90,
      minWidth: 90,
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: orders,
      initialState: {pageSize: 10}
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const fetchData = async () => {
    try {
      setIsLoading(true)

      const {data: paymentsByExpertID} = await api.get('paymentsByExpertID', {params: {expertId: user.id}})
      const { data: ordersByExpertID } = await api.get('ordersByExpertID', {params: {expertId: user.id}})
      
      const paymentData = paymentsByExpertID?.find(i => i.id === paymentId)
      const ordersArr = ordersByExpertID
        ?.filter(i => paymentData?.order_ids?.map(j => j.id)?.includes(i?.id))
        ?.map(i => ({...i, ...paymentData?.order_ids?.find(j => j.id === i.id)})) || []

      setPayment(paymentData)
      setOrders(ordersArr)
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Flex flexDirection={"column"}>
      <div onClick={goBack} className='back-link'>
        <BackArrow /> {t('portal_payment_history_back_button')}
      </div>
      <h2 className="title">{t('portal_payment_detail_title')}</h2>
      <p className="descr">{t('portal_payment_detail_text')}</p>
      
      <hr className="hr" />

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && <p className="error">{t('portal_error_message')}</p>}
      {!isLoading && !isError ? (
        orders?.length ? (
          <>
            <Text
              fontSize={15}
              color={'#3D2541'}
              mb={'20px'}
            >
              <b>{t('portal_payment_detail_title_3')}:</b>&nbsp;
              {moment(payment?.time).format("DD/MM/YYYY")}
            </Text>
            <Text
              fontSize={15}
              color={'#3D2541'}
              mb={'20px'}
            >
              <b>{t('portal_payment_detail_title_4')}:</b>&nbsp;
              {payment?.acr}%
            </Text>
            <Text
              fontSize={15}
              color={'#3D2541'}
              mb={'20px'}
              textTransform={'capitalize'}
            >
              <b>{t('portal_payment_detail_title_5')}:</b>&nbsp;
              {payment?.payment_type}
            </Text>
            <label className="custom-input-wrapper">
              <SearchIcon color={'#3D2541'} w="18px" h="18px" />
              <input
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  onChange(e.target.value);
                }}
                placeholder={"Search"}
                className={'custom-input'}
              />
            </label>
            <div
              className="table"
              {...getTableProps()}
            >
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <div
                        className="th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="table-sort">
                          {column.canSort ? (
                            <>
                              {column?.isSortedDesc && <> &#9660;</>}
                              {column?.isSortedDesc === false && <> &#9650;</>}
                              {column?.isSortedDesc === undefined && (
                                <span className='sort-arrows'>
                                  <span>&#9650;</span>
                                  <span>&#9660;</span>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="tbody" {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div 
                      key={row.original.id} 
                      className="tr" 
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <div className="td" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <Flex justifyContent="flex-end">
              <div>
                <Flex gap={'20px'}>{t('portal_payment_detail_title_2')}:<b>{payment?.currency === 'eur' ? '€' : '£'}{(payment?.consumerTotal / 100).toFixed(2)}</b></Flex>
                <Flex gap={'20px'} justifyContent={'space-between'}>{t('portal_payment_detail_title_6')}:<b>{payment?.currency === 'eur' ? '€' : '£'}{(payment?.total / 100).toFixed(2)}</b></Flex>
              </div>
            </Flex>

            <Pagination
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
            />
          </>
        ) : null
      ) : null}
    </Flex>
  )
}

export default PaymentDetails