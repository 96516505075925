import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { SearchIcon } from '@chakra-ui/icons'
import { Flex, Spinner } from '@chakra-ui/react'
import { BackArrow } from 'components/Icons/Icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useAsyncDebounce, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/Pagination'
import api from 'api'

const PaymentHistory = () => {
  const { t } = useTranslation();
  const history = useHistory()

  const { id } = useSelector(state => state.user)

  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("");

  const columns = useMemo(() => [
    {
      Header: t('portal_payment_history_table_column_4_title'),
      accessor: 'ref',
      Cell: (row) => row.row.original?.ref ? <Link to={`/admin/payments/${row.row.original?.id}`}><u>{row.row.original?.ref?.replace('LD', '')}</u></Link> : '-',
      width: 110,
      maxWidth: 110,
      minWidth: 110,
    },
    {
      Header: t('portal_payment_history_table_column_1_title'),
      accessor: "time",
      Cell: ({ value }) => value 
        ? <span title={moment(value).format("HH:mm MMM DD YYYY")}>
            {moment(value).format("DD/MM/YYYY")}
          </span> 
        : "-",
      width: 90,
      maxWidth: 90,
      minWidth: 90,
      sortType: (a, b) => {
        if (new Date(a.values.time).getTime() < new Date(b.values.time).getTime()) {
          return -1;
        } else if (new Date(a.values.time).getTime() > new Date(b.values.time).getTime()) {
          return 1;
        };
  
        return 0;
      },
    },
    {
      Header: t('portal_payment_history_table_column_2_title'),
      accessor: "status",
      Cell: ({ value }) => value ? (value.charAt(0).toUpperCase() + value.slice(1)) : 'Abandoned'
    },
    {
      Header: () => <div className='products-table-title'>
        {t('portal_payment_history_table_column_5_title')}
        <span>{t('portal_payment_history_table_column_6_title')}</span>
      </div>,
      accessor: "consumerTotal",
      Cell: (row) => <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original?.consumerTotal / 100).toFixed(2)}</b>
    },
    {
      Header: () => <div className='products-table-title'>
        {t('portal_payment_history_table_column_3_title')}
        <span>{t('portal_payment_history_table_column_7_title')}</span>
      </div>,
      accessor: "total",
      Cell: (row) => <b>{row.row.original?.currency === 'eur' ? '€' : '£'}{(row.row.original?.total / 100).toFixed(2)}</b>
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: payments,
      initialState: {pageSize: 10}
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const fetchData = async () => {
    try {
      setIsLoading(true)

      let {data: paymentsByExpertID} = await api.get('paymentsByExpertID', {params: {expertId: id}})

      paymentsByExpertID = paymentsByExpertID?.sort((a, b) => new Date(a?.time).getTime() < new Date(b?.time).getTime() ? -1 : 1) || []
      setPayments(paymentsByExpertID)
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  return (
    <Flex flexDirection={"column"}>
      <div onClick={goBack} className='back-link'>
        <BackArrow /> {t('portal_payment_history_back_button')}
      </div>
      <h2 className="title">{t('portal_payment_history_top_title')}</h2>
      <p className="descr">{t('portal_payment_history_top_description')}</p>

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && <p className="error">{t('portal_error_message')}</p>}
      {!isLoading && !isError ? (
        payments?.length ? (
          <>
            <label className="custom-input-wrapper">
              <SearchIcon color={'#3D2541'} w="18px" h="18px" />
              <input
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  onChange(e.target.value);
                }}
                placeholder={"Search"}
                className={'custom-input'}
              />
            </label>
            <div
              className="table"
              {...getTableProps()}
            >
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <div
                        className="th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="table-sort">
                          {column.canSort ? (
                            <>
                              {column?.isSortedDesc && <> &#9660;</>}
                              {column?.isSortedDesc === false && <> &#9650;</>}
                              {column?.isSortedDesc === undefined && (
                                <span className='sort-arrows'>
                                  <span>&#9650;</span>
                                  <span>&#9660;</span>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="tbody" {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div 
                      key={row.original.id} 
                      className="tr" 
                      {...row.getRowProps()}
                      style={{
                        ...row.getRowProps().style, 
                        background: row.original.payment_type === "paid_out" ? '#bfefbf' : ''
                      }}
                    >
                      {row.cells.map((cell) => (
                        <div className="td" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <Pagination
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
            />
          </>
        ) : (
          <p className="error">{t('portal_payment_history_no_payments')}</p>
        )
      ) : null}
    </Flex>
  )
}

export default PaymentHistory