import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "api";

const RequestQuestionnaireBtn = ({ userData, setMailgunStats }) => {
  const { t } = useTranslation();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const toast = useToast();

  const [requestType, setRequestType] = useState("intro");
  const [emailIsSending, setEmailIsSending] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const sendEmail = async () => {
    try {
      onClose();
      setEmailIsSending(true);

      const { data: expert } = await api.get("userByID", {
        params: { userId: userData?.expertId },
      });

      api.post("sendMailgun", {
        user_id: userData.id,
        to: userData?.email,
        subject:
          requestType === "photos"
            ? "Upload pictures"
            : "Request Questionnaire",
        template:
          requestType === "photos"
            ? "upload pictures"
            : "request questionnaire",
        params: {
          button_link: encodeURI(
            `${process.env.REACT_APP_QUESTIONNAIRE_URL}questionnaire?uid=${userData.id}&mark=${requestType}`
          ),
          button_name: "Continue",
          user_name: `${userData?.firstName} ${userData?.lastName}`,
          expert_name: `${expert?.firstName} ${expert?.lastName}`,
        },
      });

      toast({
        title: "Email sent successfully",
        position: "top-right",
        isClosable: true,
        status: "success",
      });

      const { data: mailgunStats } = await api.get("getMailgunStatsByUserId", {
        params: { user_id: userData.id },
      });
      setMailgunStats(mailgunStats);

      localStorage.setItem(`email_sent_${userData.id}`, Date.now());
      setDisabled(true);
    } catch (err) {
      toast({
        title: err.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    } finally {
      setEmailIsSending(false);
    }
  };

  const copyHandler = () => {
    onClose();
    window.navigator.clipboard.writeText(
      `${process.env.REACT_APP_QUESTIONNAIRE_URL}questionnaire?uid=${userData.id}&mark=${requestType}`
    );
    toast({
      title: "Copied successfully",
      position: "top-right",
      isClosable: true,
      status: "success",
    });
  };

  useLayoutEffect(() => {
    setDisabled(true);

    if (!Object.keys(userData)?.length) return;

    const storageDate = localStorage.getItem(`email_sent_${userData.id}`);

    if (!storageDate) {
      setDisabled(false);
      return;
    }

    if (new Date().getDate() > new Date(Number(storageDate)).getDate()) {
      setDisabled(false);
    }
  }, [userData]);

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end">
      <PopoverTrigger>
        <button className="outlined-btn" onClick={onToggle} disabled={disabled}>
          {t(
            disabled
              ? "portal_customers_view_weekly_questionnaire_2"
              : "portal_customers_view_weekly_questionnaire"
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent boxShadow={"none !important"} w="265px">
        <PopoverArrow />
        <PopoverBody>
          <Flex p="8px" gap="10px" justifyContent="center" mb="10px">
            {["Intro", "Weekly", "Photos"].map((i) => (
              <label key={i} className="assessment-label" style={{ margin: 0 }}>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name={"requestType"}
                    value={i.toLowerCase()}
                    checked={requestType === i.toLowerCase()}
                    onChange={() => setRequestType(i.toLowerCase())}
                  />
                  <span />
                </div>
                {i}
              </label>
            ))}
          </Flex>
          <Flex p="8px" gap="20px" justifyContent="center">
            {emailIsSending ? (
              <Spinner color="red.700" size="sm" />
            ) : (
              <button className="custom-link" onClick={sendEmail}>
                Send email
              </button>
            )}
            <button className="custom-link" onClick={copyHandler}>
              Copy link
            </button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default RequestQuestionnaireBtn;
