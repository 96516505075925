import * as yup from "yup"

const requireString = yup.string().required('This field is required')

export const schema = yup.object({
  firstName: requireString,
  lastName: requireString,
  gender: requireString,
  language: requireString,
  notes: yup.string(),
  email: yup.string().email('Invalid E-mail').required('This field is required'),
  fullname: requireString,
  // phone: requireString,
  country: requireString,
  city: requireString,
  postal_code: requireString,
  // state: requireString,
  line1: requireString,
});