import { Flex, Spinner, Text, Textarea, useDisclosure } from '@chakra-ui/react'
import api from 'api'
import { BackArrow } from 'components/Icons/Icons'
import { doc, getDoc } from 'firebase/firestore'
import { updateStorageOrders } from 'helpers'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { db } from '../../../firebase'
import ProductModal from './ProductModal'
import ProductsTable from './ProductsTable'

const OrdersSend = () => {
  const { t } = useTranslation();
  const history = useHistory()
  const { orderId, treatmentId } = useParams()
  const {user: expert} = useSelector(state => state)

  const [order, setOrder] = useState({})
  const [user, setUser] = useState({})
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isSendLoading, setIsSendLoading] = useState(false)
  const [isSendError, setIsSendError] = useState(false)
  const [productToEdit, setProductToEdit] = useState(null)
  const [commentBlock, setCommentBlock] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const fetchData = async () => {
    try {
      setIsLoading(true)

      const { data: orderData } = await api.get('orderByID', { params: { orderId } })

      if(orderData) {
        setOrder(orderData)

        const { data: userByID } = await api.get('userByID', { params: { userId: orderData.user_id } })
  
        if (userByID) {
          setUser(userByID)
  
          const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: orderData.user_id } })
          const treatment = treatmentsByUserId?.find(i => i?.id === treatmentId)
          setProducts(treatment?.products)
          setCommentBlock(treatment?.additional_comment_аlternate_block)
        }
      }

    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const createPackage = async (userData) => {
    try {
      let shiftObj = null
      const shipmentRef = doc(db, "Meta", "shipment")
      const shipmentSnap = await getDoc(shipmentRef);

      if (shipmentSnap.exists()) {
        shiftObj = shipmentSnap.data()
      }

      let ship_by = new Date();
      ship_by.setSeconds(ship_by.getSeconds() + shiftObj?.ship_by_shift);
      let package_due = new Date();
      package_due.setSeconds(package_due.getSeconds() + shiftObj?.package_due_shift);

      let risk = "yellow";
      if (userData?.paymentPlanActive) {
        if (userData.paymentPlanActive < Math.floor(Date.now() / 1000)) {
          risk = "red";
        } else {
          risk = "green";
        }
      }

      const p = Object.entries(products)?.length ?
        Object.entries(products).map(i => ({
          value: i[0],
          ...i[1]
        })) : []

      let obj = {
        ref: 'LD' + Math.floor(Date.now() / 1000),
        user_id: userData.id,
        userName: (userData?.firstName && userData?.lastName) ? userData?.firstName + ' ' + userData?.lastName : userData?.name,
        userEmail: userData.email,
        shippingAddress: userData?.shippingAddress || {},
        status: 'waiting_shipment',
        products: p,
        package_due,
        ship_by,
        test: userData?.isTestUser || 0,
        risk: risk,
        deleted: 0,
        treatmentId,
        type: order?.type,
        orderId,
        expertId: expert.id
      }

      obj.shippingAddress.email = userData.email

      if (userData?.vip) obj.vip = userData.vip

      const {data: packageRes} = await api.post('createPackage', obj)

      if(order) {
        await api.post('updateOrder', {
          id: orderId,
          packageId: packageRes.insertId,
          status: 'delivery_needed',
          submitted: 1
        })
        await updateStorageOrders(
          expert.id, 
          user, 
          {
            ...order, 
            packageId: packageRes.insertId, 
            status: 'delivery_needed', 
            submitted: 1
          }, 
          orderId
        )
      }
    } catch (err) {
      console.log('!!!package_add crash: ' + err.message)
      setIsSendError(true)
    }
  }

  const onSend = async () => {
    setIsSendLoading(true)

    try {
      const {data: userData} = await api.get('userByID', { params: { userId: user.id } })
      
      await api.post('updateTreatment', {
        id: treatmentId, 
        isTreatment: true, 
        version: 3,
        additional_comment_аlternate_block: commentBlock
      })
      await api.post('updateUser', {id: user.id, currentTreatmentID: treatmentId})

      if(order.status === 'paid_shopify' || order.status === 'paid_stripe' || order.paymentId) {
        await createPackage(userData)
      } else {
        await api.post('updateOrder',{
          id: orderId, 
          submitted: 1,
          status: 'payment_needed'
        })
        await updateStorageOrders(
          expert.id, 
          user, 
          {
            ...order, 
            submitted: 1, 
            status: 'payment_needed'
          }, 
          orderId
        )
      }

      history.push('/admin/orders')
    } catch (err) {
      setIsSendError(true)
    } finally {
      setIsSendLoading(false)
    }
    // try {
    //   let results = await axios.get(`${CRM_URL}/s/crmadd/31000024067/${expert.id}`)
    //   console.log("On crm add", results);
    // } catch (err) {
    //   console.log("On crm add",err);
    // }
  }

  const onEdit = (product) => {
    setProductToEdit(product)
    onOpen()
  }

  const onDelete = async (ref) => {
    if (confirm('Do you really want to delete this product?')) {
      const filteredProducts = Object.fromEntries(Object.entries(products).filter(([key, value]) => key !== ref))
      
      setProducts(filteredProducts)
      
      await api.post('updateTreatment', {id: treatmentId, products: filteredProducts})
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Flex flexDirection={"column"}>
      <Link to={`/admin/orders/new/${order.user_id}/${orderId}`} className='back-link'>
        <BackArrow /> {t('portal_orders_new_back_button')}
      </Link>
      <h2 className="title">{t('portal_orders_new_treatment_top_title')}</h2>
      <p className="descr">{t('portal_orders_new_treatment_top_description')}</p>

      <hr className="hr" />

      {isLoading && (
        <Flex mx={"auto"}>
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <p className="error" style={{margin: '0 auto'}}>{t('portal_error_message')}</p>
      )}
      {!isLoading && !isError ? (
        <>
          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          >
            <b>{t('portal_orders_new_treatment_name')}</b>&nbsp;
            {
              ((user?.firstName && user?.lastName) && `${user?.firstName} ${user?.lastName}`) ||
              user?.name || '-'
            }
          </Text>
          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>{t('portal_orders_new_treatment_email')}</b>&nbsp; {user.email}</Text>

          <hr className="hr" />

          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <h2 className='title' style={{ color: '#3D2541' }}>{t('portal_orders_new_treatment_package_title')}</h2>
            {(expert?.canEditOrder || expert.role_id == 2) &&
              <button
                type="button"
                className="custom-link"
                onClick={onOpen}
              >
                {t('portal_orders_new_add_product')}
              </button>
            }
          </Flex>

          <ProductsTable 
            products={products}
            canEdit={(expert?.canEditOrder || expert.role_id == 2)}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <Flex direction='column' gap={2} mt={4}>
            <Text fontSize={17} fontWeight='bold' color={'#92001a'}>Additional information on the treatment plan</Text>
            <Textarea
              value={commentBlock}
              onChange={(event) => {
                setCommentBlock(event.target.value)
              }}
              resize='none'
            />
          </Flex>

          {(expert?.canEditOrder || expert.role_id == 2) &&
            <ProductModal 
              product={productToEdit}
              oldProducts={products}
              setNewProducts={setProducts}
              userID={user.id}
              treatmentID={treatmentId}
              isOpen={isOpen} 
              onClose={() => {
                onClose()
                setProductToEdit(null)
              }}
            />
          }

          {!isSendLoading && isSendError && (
            <p className="error" style={{margin: '0 auto'}}>{t('portal_error_message')}</p>
          )}
          <hr className="hr" />
          <Flex justifyContent={"end"}>
            {!order.submitted &&
              <button
                type="button"
                className="custom-btn"
                disabled={isSendLoading}
                onClick={onSend}
              >
                {isSendLoading ? t('portal_loading') : t('portal_orders_new_treatment_bottom_button')}
              </button>
            }
          </Flex>
        </>
      ) : null}
    </Flex>
  )
}

export default OrdersSend