import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import moment from "moment";
import api from "api";
import { ReactComponent as InfoIcon } from "assets/svg/info-circle.svg";
import Checkbox from "components/Checkbox";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { updateStorageOrders } from "helpers";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { db } from "../../../../firebase";
import getFields from "./AssessmentFormFields";

const AssessmentForm = ({
  userData,
  orderId,
  initialState,
  viewOnly,
  prices,
  discount,
  currency,
  latestOrder,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const { answerId } = useSelector((state) => state.assessment);

  const { box_composed_by_ld, questionnaire_passed, ...rest } =
    initialState || {};
  const notEmpty = Object.keys(rest)?.length;

  const [formState, setFormState] = useState({});

  const [validateError, setValidateError] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [idError, setIdError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAf, setIsLoadingAf] = useState(false);
  const [isError, setIsError] = useState(false);

  const fields = getFields(t);

  const checkDisable = (key) => {
    if (key === "acne_problem_area") {
      return !formState.hasOwnProperty("acne") || formState.acne === "none";
    }
    if (key === "seborrhea_problem_area") {
      return (
        !formState.hasOwnProperty("seborrhea") || formState.seborrhea === "no"
      );
    }
    if (key === "hyperpigmentation_problem_area") {
      return (
        !formState.hasOwnProperty("hyperpigmentation") ||
        formState.hyperpigmentation.includes("none")
      );
    }
  };

  const changeHandler = (key, value) => {
    if (key === "acne" && value === "none") {
      setFormState({ ...formState, acne_problem_area: [], [key]: value });
    } else if (key === "seborrhea" && value === "no") {
      setFormState({ ...formState, seborrhea_problem_area: [], [key]: value });
    } else if (
      [
        "acne_problem_area",
        "seborrhea_problem_area",
        "hyperpigmentation_problem_area",
        "hyperpigmentation",
      ].includes(key)
    ) {
      if (key === "hyperpigmentation" && value === "none") {
        setFormState({
          ...formState,
          hyperpigmentation_problem_area: [],
          [key]:
            formState?.[key]?.includes(value) &&
            typeof formState[key] !== "string"
              ? formState[key].filter((i) => i !== value)
              : [value],
        });
      } else {
        const newState = Array.isArray(formState[key])
          ? formState[key].includes(value)
            ? formState[key].filter((i) => i !== value)
            : [...formState[key].filter((i) => i !== "none"), value]
          : [value];

        setFormState({ ...formState, [key]: newState });
      }
    } else {
      setFormState((prev) => ({ ...prev, [key]: value }));
    }

    localStorage.setItem(`${userData?.id}_time`, Date.now());
  };

  const submitHandler = async (ev) => {
    ev.preventDefault();
    const emptyFieldsArr = fields.filter(
      (field) =>
        (field.key === "hyperpigmentation" &&
          (!formState[field.key]?.length ||
            typeof formState[field.key] === "string")) ||
        (!checkDisable(field.key) && !formState[field.key]?.length) ||
        !formState.hasOwnProperty(field.key)
    );

    if (emptyFieldsArr?.length) {
      setEmptyFields(emptyFieldsArr.map((field) => field.key));
      setValidateError(true);
      return;
    }

    if (!userData?.id) {
      setIdError(true);
      return;
    }

    let newOrder = {};

    try {
      setIsLoading(true);

      localStorage.setItem(
        `formState_${userData?.id}`,
        JSON.stringify(formState)
      );

      const reqRef = collection(db, "Users", userData?.id, "TreatmentRequests");
      const reqSnap = await getDocs(reqRef);
      if (reqSnap.empty) {
        await addDoc(reqRef, {
          treatmentRequest: serverTimestamp(),
          treatmentRequestDone: false,
        });
      }

      let oId = "";
      let treatmentId = "";
      if (orderId) {
        const { data: orderData } = await api.get("orderByID", {
          params: { orderId },
        });

        if (orderData) {
          oId = orderData.id;
          let afId = "";

          if (orderData?.assessmentId) {
            afId = orderData.assessmentId;
            await updateDoc(doc(db, "AssessmentForms", afId), {
              ...formState,
              userId: userData?.id,
              answerId: answerId ? answerId : "",
              expertId: user.id,
              time: serverTimestamp(),
            });
          } else {
            const assessmentRef = collection(db, "AssessmentForms");
            const docRef = await addDoc(assessmentRef, {
              ...formState,
              ref: "LD" + Math.floor(Date.now() / 1000),
              userId: userData?.id,
              answerId: answerId ? answerId : "",
              expertId: user.id,
              time: serverTimestamp(),
            });
            afId = docRef.id;
          }

          const res = await api.get(`s/parseAF/${afId}`);
          treatmentId = res.data;

          if (
            orderData?.status === "paid_shopify" ||
            orderData?.status === "paid_stripe" ||
            orderData?.paymentId
          ) {
            const orderObj = {
              type: "rb",
              assessmentId: afId,
              expertId: user.id,
              submitted: 0,
              treatmentId: res.data,
              currency: currency === "HU" ? "eur" : "gbp",
            };
            newOrder = orderObj;
            await api.post("updateOrder", { id: orderId, ...orderObj });
          } else if (orderData?.status === "questionnaire_needed") {
            const orderObj = {
              assessmentId: afId,
              status: "payment_needed",
              treatmentId: res.data,
              submitted: 0,
              currency: currency === "HU" ? "eur" : "gbp",
            };
            newOrder = orderObj;
            await api.post("updateOrder", { id: orderId, ...orderObj });
          } else {
            const orderObj = {
              assessmentId: afId,
              treatmentId: res.data,
              submitted: 0,
              currency: currency === "HU" ? "eur" : "gbp",
            };
            newOrder = orderObj;
            await api.post("updateOrder", { id: orderId, ...orderObj });
          }
        }
      } else {
        const assessmentRef = collection(db, "AssessmentForms");
        const docRef = await addDoc(assessmentRef, {
          ...formState,
          ref: "LD" + Math.floor(Date.now() / 1000),
          userId: userData?.id,
          answerId: answerId ? answerId : "",
          expertId: user.id,
          time: serverTimestamp(),
        });

        const res = await api.get(`s/parseAF/${docRef.id}`);
        treatmentId = res.data;

        const orderObj = {
          ref: "LD" + Math.floor(Date.now() / 1000),
          type: "rb",
          assessmentId: docRef.id,
          expertId: user.id,
          paymentId: null,
          status: "payment_needed",
          user_id: userData?.id,
          submitted: 0,
          treatmentId: res.data,
          currency: currency === "HU" ? "eur" : "gbp",
        };
        const result = await api.post("createOrder", orderObj);

        oId = result?.data?.insertId;
        newOrder = { id: oId, ...orderObj };
      }

      setValidateError(false);
      setIdError(false);

      await updateStorageOrders(user.id, userData, newOrder, orderId);
      history.push(`/admin/orders/send/${oId}/${treatmentId}`);
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const changeFormState = async () => {
    setIsLoadingAf(true);
    setFormState(latestOrder);
    setIsLoadingAf(false);
  };

  useLayoutEffect(() => {
    if (initialState) {
      setValidateError(false);
      setFormState(initialState);
    }
  }, [initialState]);

  useEffect(() => {
    if (latestOrder) {
      changeFormState();
    }
  }, [latestOrder]);

  if (isLoadingAf) {
    return (
      <Flex justifyContent="center">
        <Spinner color="red.700" size="xl" />
      </Flex>
    );
  }

  if (!notEmpty && viewOnly) {
    return null;
  }

  return (
    <div
      className={`assessment-wrapper ${viewOnly && "assessment-wrapper-view"}`}
    >
      {latestOrder && Object.keys(latestOrder).length > 0 && (
        <Text className="descr" marginBottom="2">
          <Text display="inline-block" paddingRight="2">
            {t(
              "portal_orders_new_prev_assessment",
              "The date of the last assessment:"
            )}
          </Text>
          <Text display="inline-block" textDecoration="underline">
            {moment(latestOrder.time.seconds * 1000).format(
              "HH:mm DD/MMM/YYYY"
            )}
          </Text>
        </Text>
      )}
      <form onSubmit={submitHandler}>
        {!viewOnly && (
          <FormControl mb={"30px"} w={{ lg: "440px", sm: "100%" }}>
            <FormLabel fontSize={"15px"} fontWeight={600} color={"#33D2541"}>
              {t("portal_orders_new_doctor_name_title")}
            </FormLabel>
            <Input
              h={"44px"}
              borderColor={"#3D2541"}
              type="text"
              required
              placeholder={t("portal_orders_new_doctor_name_placeholder")}
              value={formState.doctorName || ""}
              onChange={(ev) => changeHandler("doctorName", ev.target.value)}
            />
          </FormControl>
        )}

        {fields.map((field) => (
          <div key={field.key} className="assessment-field">
            <Text
              className="assessment-title"
              color={emptyFields.includes(field.key) ? "red.400" : "#3D2541"}
            >
              {t(field.title)}:
            </Text>
            <div className="assessment-variants">
              {field.variants.map((variant) => (
                <label key={variant.value} className="assessment-label">
                  {field.multi ? (
                    <Checkbox
                      disabled={notEmpty || checkDisable(field.key)}
                      name={field.key}
                      value={variant.value}
                      checked={
                        Array.isArray(formState[field.key]) &&
                        formState[field.key].includes(variant.value)
                      }
                      onChange={() => changeHandler(field.key, variant.value)}
                    />
                  ) : (
                    <div className="custom-radio">
                      <input
                        disabled={notEmpty || checkDisable(field.key)}
                        type="radio"
                        name={field.key}
                        value={variant.value}
                        checked={formState[field.key] === variant.value}
                        onChange={() => changeHandler(field.key, variant.value)}
                      />
                      <span />
                    </div>
                  )}

                  {t(variant.label)}
                </label>
              ))}
            </div>
          </div>
        ))}

        {!viewOnly && (
          <Box w={{ lg: "440px", sm: "100%" }} mb="20px">
            <Text
              fontSize={"15px"}
              fontWeight={600}
              color={"#33D2541"}
              mt={"30px"}
              mb={"7px"}
            >
              {t("portal_orders_new_af_comment_title")}
            </Text>
            <CKEditor
              editor={ClassicEditor}
              data={formState.recommendation || ""}
              onChange={(event, editor) =>
                changeHandler("recommendation", editor.getData())
              }
            />
          </Box>
        )}

        {validateError && (
          <Text color="red.400">{t("portal_orders_new_error_1")}</Text>
        )}
        {idError && (
          <Text color="red.400">{t("portal_orders_new_error_2")}</Text>
        )}
        {isError && <Text color="red.400">{t("portal_error_message")}</Text>}

        {!notEmpty && !viewOnly && (
          <div className="assessment-btn">
            <hr className="hr" />
            <Flex
              justifyContent={"space-between"}
              flexDirection={{ lg: "row", sm: "column" }}
              gap={{ lg: 0, sm: "20px" }}
            >
              <div className="new-order-price">
                <InfoIcon /> {t("portal_orders_new_total")}
                <span>
                  {currency === "UK" ? "£" : "€"}
                  {prices?.[`rbPricePartner${discount}${currency}`] / 100}
                </span>
              </div>
              <button
                type="submit"
                className="custom-btn"
                disabled={notEmpty || isLoading}
              >
                {isLoading
                  ? t("portal_orders_new_af_loading")
                  : t("portal_orders_new_af_bottom_button")}
              </button>
            </Flex>
          </div>
        )}
      </form>
    </div>
  );
};

export default AssessmentForm;
